import Markdown from "react-markdown"
import LockIcon from '../../components/LockIcon'

type LockedItemProps = {
  title: string
  className?: string
}
const LockedItem = ({ title, className }: LockedItemProps) => {
  return (
    <div className={`flex py-2 ${className}`}>
      <p className="text-base font-semibold flex-1">{title}</p>
      <LockIcon />
    </div>
  )
}

type Props = {
  summary: string
  onUnlockFullReviewClick?: VoidFunction
}

export const PartialProfileReview = ({ summary, onUnlockFullReviewClick = undefined }: Props) => (
  <>
    <p className="text-xl text-neutral-900 font-semibold mb-2">Summary</p>
    <div className="prose prose-base leading-tight">
      <Markdown>{summary}</Markdown>
    </div>
    <LockedItem className="mt-3" title="Quick wins" />
    <LockedItem className="mt-1" title="Photo by photo feedback" />
    <LockedItem className="mt-1" title="Optimal photo order" />
    <LockedItem className="mt-1" title="Bio review & improvements" />
    <LockedItem className="mt-1" title="Clear action plan" />
    <button
      className="mt-4 flex items-center justify-center bg-brand-primary text-white h-16 rounded-lg font-semibold hover:bg-brand-primary-dark w-full p-6 text-base rounded-[32px] bg-gradient-to-b from-brand-primary to-[#FF8A65]"
      onClick={onUnlockFullReviewClick}>
      <span className="mr-2"><LockIcon className="text-white" /></span>
      <p className="text-xl">Get Full Review</p>
    </button>
  </>
)