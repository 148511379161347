import { uploadFiles } from '../../queries'

const isValidFileType = (file: File) => {
  // Updated to match Google Gemini supported formats
  const validExtensions = [
    'image/png',    // PNG
    'image/jpeg',   // JPEG
    'image/jpg',    // JPEG (alternative MIME type)
    'image/webp',   // WEBP
    'image/heic',   // HEIC
    'image/heif'    // HEIF
  ];
  // alert('valid file type');
  return validExtensions.includes(file.type);
};

const isValidFileSize = (file: File) => {
  // Updated to 7MB per Gemini constraints
  const maxSize = 7 * 1024 * 1024; // 7MB in bytes
  return file.size <= maxSize;
};

type Props = {
  onUploadFailed?: (error: string) => void
  onUploadStart?: (files: FileList | null) => void
  onUploadSuccess?: (urls: string[]) => void
}

export const useFileUpload = ({ onUploadFailed = undefined, onUploadStart = undefined, onUploadSuccess = undefined }: Props) => {
  const onFileUpload = (files: FileList | null) => {
    if (!files || files.length === 0) return

    // setSelectedFiles(files);
    const filesArray = Array.from(files);

    if (filesArray.length > 12) {
      // Inform the user they can only upload up to 12 images
      // toast.error("Please upload up to 12 screenshots");
      onUploadFailed?.("Please upload up to 12 screenshots")
      return; // Prevent further execution
    }

    // Check for valid file types
    if (!filesArray.every(isValidFileType)) {
      // toast.error("Unsupported file type. Screenshots must be png, jpg, webp, heic, or heif");
      onUploadFailed?.("Unsupported file type. Please use PNG, JPEG, WEBP, HEIC, or HEIF format")
      // setInputKey(Date.now()); // Reset the input
      return; // Stop further execution
    }

    // Check file sizes
    const oversizedFiles = filesArray.filter(file => !isValidFileSize(file));
    if (oversizedFiles.length > 0) {
      // Provide more specific feedback about which files are too large
      if (oversizedFiles.length === 1) {
        onUploadFailed?.(`Oops! "${oversizedFiles[0].name}" is too big. Keep files under 7MB.`)
      } else {
        onUploadFailed?.(`${oversizedFiles.length} files are too big. Keep each under 7MB.`)
      }
      return; // Stop further execution
    }

    // Call additional handler functions here if needed
    // e.g., uploadFiles(filesArray);

    // setSelectedFiles(files);
    // setFilesUploading(true);
    onUploadStart?.(files)

    uploadFiles(files).then((response) => {
      // we have the URLs for the files, call onFilesUploaded
      // onFilesUploaded(response.data.urls);
      // // set that we are no longer uploading
      // setFilesUploading(false);
      onUploadSuccess?.(response.data.urls)
    }).catch(error => {
      console.error("Error uploading files:", error);
      onUploadFailed?.("Failed to upload files. Please try again.")
    });
  }

  return {
    onFileUpload
  }
}