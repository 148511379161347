import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import abandonCartGraphic from "../../assets/images/misc/abandon_cart_offer_50.webp";
import { useRef, useState, useEffect } from "react";
import { usePaymentStore } from "../../stores/payment";
import { PlanType } from "../../constants/payments";
import { Dialog } from "@headlessui/react";
import { logEvent } from "../../analytics/analyticsUtils";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  children?: React.ReactNode;
  onClose: () => void;
  handleGetStarted: () => void;
  containerStyle?: string;
  onDismiss?: () => void;
}

export const AbandonCartModal = ({
  open,
  setOpen,
  children,
  onClose,
  handleGetStarted: parentHandleGetStarted,
  containerStyle = "",
  onDismiss,
}: Props) => {
  const { setPlanType, setOpenPaymentModal, setIsAbandonCartFlow } = usePaymentStore();
  const [dragStartY, setDragStartY] = useState<number | null>(null);
  const [currentDragY, setCurrentDragY] = useState<number>(0);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleGetStarted = () => {
    logEvent('abandon_cart_modal_click', 'claim_offer');
    setPlanType(PlanType.Yearly);
    setIsAbandonCartFlow(true);
    setOpen(false);
    setOpenPaymentModal(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose?.();
    onDismiss?.();
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setDragStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (dragStartY === null) return;
    
    const currentY = e.touches[0].clientY;
    const deltaY = currentY - dragStartY;
    
    // Only allow dragging downwards
    if (deltaY > 0) {
      setCurrentDragY(deltaY);
    }
  };

  const handleTouchEnd = () => {
    // If dragged more than 150px down, close the modal
    if (currentDragY > 150) {
      handleClose();
    }
    
    // Reset drag state
    setDragStartY(null);
    setCurrentDragY(0);
  };

  return (
    <>
      <Transition
        show={open}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed inset-0 bg-black/60 backdrop-blur-sm z-[100] cursor-pointer"
        onClick={handleClose}
      />

      <Transition
        show={open}
        enter="transition ease-out duration-300"
        enterFrom="transform translate-y-full"
        enterTo="transform translate-y-0"
        leave="transition ease-in duration-200"
        leaveFrom="transform translate-y-0"
        leaveTo="transform translate-y-full"
        className={`fixed left-0 right-0 mx-auto bg-white rounded-t-[16px] p-6 md:p-8 lg:p-10 z-[101] shadow-xl bottom-0 w-full max-w-[450px] md:max-w-[650px] lg:max-w-[700px] max-h-[90vh] overflow-y-auto ${containerStyle}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-full mx-auto">
          {/* Grey bar indicator */}
          <div 
            className="flex justify-center -mt-2 mb-4 md:mb-6 cursor-grab active:cursor-grabbing sticky top-0 bg-white"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div className="w-16 h-1.5 rounded-full" style={{ backgroundColor: '#D1D1D6' }} />
          </div>

          <div className="flex flex-col items-center md:space-y-1">
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold text-[#410B13] leading-relaxed mb-0">
              Your one time offer
            </h2>

            <div className="-mt-4 -mb-4">
              <img 
                src={abandonCartGraphic} 
                alt="Special offer graphic"
                className="w-56 h-56 md:w-64 md:h-64 lg:w-72 lg:h-72 object-contain"
              />
            </div>

            <div className="text-center -mt-2">
              <div className="flex flex-col items-center text-2xl md:text-3xl lg:text-4xl font-semibold text-[#410B13] pb-1">
                <div className="flex items-center gap-4">
                  <span className="line-through text-black">$71.99</span>
                  <span className="text-black font-normal">$35.99/year</span>
                </div>
              </div>
            </div>

            <div className="text-center mb-6 pb-2 md:mb-8">
              <p className="text-sm md:text-base leading-relaxed max-w-2xl text-gray-600">
                Once you close your one time offer, it's gone!
              </p>
            </div>

            <div className="w-full space-y-4 md:space-y-6">
              {/* Limited time discount box */}
              <div className="max-w-sm md:max-w-md mx-auto w-full">
                <div className="rounded-[20px] overflow-hidden cursor-pointer relative p-[2px] bg-gradient-to-r from-[#E85C65] to-[#952057]">
                  <div className="rounded-[18px] bg-white overflow-hidden">
                    <div className="bg-gradient-to-r from-[#E85C65] to-[#952057] text-white py-1 text-sm font-medium text-center w-full">
                      Limited time discount
                    </div>
                    <div className="px-4 pt-2 pb-4">
                      <div className="flex justify-between items-start">
                        <div>
                          <h3 className="text-base font-bold text-[#1F1F1F]">Annual Plan</h3>
                          <div className="text-xs text-gray-500 mt-1">12 months</div>
                        </div>
                        <div className="text-base font-bold text-[#1F1F1F]">$2.99/mo</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Button */}
              <div className="max-w-sm md:max-w-md mx-auto w-full">
                <button
                  className="w-full py-4 bg-[#D93A3A] text-white text-base font-semibold rounded-[20px] hover:bg-[#C33232] transition-colors"
                  onClick={handleGetStarted}
                >
                  claim my offer
                </button>
              </div>

              {/* No thanks button */}
              <div className="text-center">
                <button
                  className="text-[#410B13] underline font-bold text-base md:text-lg hover:text-[#6B1D28] transition-colors py-2"
                  onClick={handleClose}
                >
                  No Thanks
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
}; 