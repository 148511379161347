import React, { ReactNode } from 'react';
import { Logo } from '../Logo';
import { useNavigate } from 'react-router-dom';

interface OnboardingContainerProps {
  children: ReactNode;
  showProgressBar?: boolean;
  currentStep?: number;
  totalSteps?: number;
  hideLogo?: boolean;
}

/**
 * A standardized container for onboarding content
 */
export const OnboardingContainer: React.FC<OnboardingContainerProps> = ({
  children,
  showProgressBar = false,
  currentStep = 0,
  totalSteps = 1,
  hideLogo = false
}) => {
  const navigate = useNavigate();
  
  return (
    <div className="max-w-sm md:max-w-xl mx-auto mt-1 px-3 h-full">
      <div className="bg-white rounded-lg pb-2 shadow-sm h-full">
        {/* Three-column layout with Logo in center and Skip on right */}
        {!hideLogo && (
          <div className="flex items-center -mt-6 mb-3">
            <div className="flex-1"></div> {/* Empty left column */}
            <div className="flex-1 flex justify-center">
              <Logo />
            </div>
            <div className="flex-1 flex justify-end pr-2">
              <button 
                onClick={() => navigate("/next-move")}
                className="text-sm font-medium text-gray-500 text-italic hover:text-brand-primary"
              >
                skip
              </button>
            </div>
          </div>
        )}
        
        {showProgressBar && (
          <div className="mb-4">
            <div className="w-full bg-gray-200  md:my-12 md:mx-auto rounded-full h-2">
              <div
                className="bg-brand-primary h-2 rounded-full transition-all duration-300 ease-in-out"
                style={{ width: `${((currentStep + 1) / totalSteps) * 100}%` }}
              ></div>
            </div>
          </div>
        )}
        <div>
          {children}
        </div>
      </div>
    </div>
  );
}; 