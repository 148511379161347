export enum PlanType {
  Monthly = "monthly",
  Yearly = "yearly",
  AbandonCartYearly = "abandon_cart_yearly",  // Special yearly plan for abandon cart flow
}

export enum ProductType {
  ProfileWriter = "profile_writer",
  ProfileReview = "profile_review",
  AIPhotos = "ai_photos",
}
export enum AIPhotosPlan {
  Basic = "basic",
  Plus = "plus",
  Premium = "premium",
}

