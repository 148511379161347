import React from 'react';
import { OnboardingWizard } from './OnboardingWizard';
import { ProductType } from '../../models/payment';
import { useWizardStore } from '../../stores/wizard';
import { WizardStepType } from '../../models/wizard';

// Import images
import step1Image from '../../assets/images/onboarding/v3/step1.webp';
import step2Image from '../../assets/images/onboarding/v3/step2.webp';
import step3Image from '../../assets/images/onboarding/v3/step3.webp';
import step4Image from '../../assets/images/onboarding/v3/step4.webp';

// Define the slide types we need
type SlideType = 'question' | 'image' | 'info' | 'intro' | 'loader' | 'features' | 'paywall';

// Generic slide interface
interface TestSlide {
  id: string;
  type: SlideType;
  content: React.ReactNode | null;
  showProgressBar?: boolean;
  showLogo?: boolean;
}

// Sample slides for testing
const testSlides = [
  // First screen with centered headline and description
  {
    id: 'welcome',
    type: 'intro' as const,
    headline: 'Dating Apps are Exhausting',
    description: 'YourMove AI helps you win. So you can spend less time swiping and more time dating.',
    content: null,
    showProgressBar: false,
    showLogo: true
  },
  // Info card with image
  // {
  //   id: 'how_it_works',
  //   type: 'info' as const,
  //   title: 'AI-Powered Profile Optimization',
  //   description: 'We analyze thousands of successful dating profiles to help you create a profile that attracts the right matches.',
  //   imageUrl: 'https://placehold.co/400x300/EAEAEA/8F8F8F?text=AI+Optimization',
  //   content: null,
  //   showProgressBar: false
  // },
  // First set of cards (without progress bar)
  {
    id: 'goal',
    type: 'question' as const,
    question: 'What\'s your next move?',
    emoji: '💞',
    options: [
      'Get more matches',
      'Find higher-quality matches',
      'Turn more matches into dates'
    ],
    content: null,
    showProgressBar: false
  },
  // Second set of cards (with progress bar)
  {
    id: 'name',
    type: 'question' as const,
    question: 'What\'s your name?',
    freeform: true,
    content: null,
    showProgressBar: true
  },
  {
    id: 'sample_image',
    type: 'image' as const,
    imageUrl: step4Image,
    title: 'AI Photoshoot',
    caption: 'Professional-quality photos using the power of AI.',
    isRecommended: true,
    content: null,
    showProgressBar: true
  }
];

export const OnboardingFlow: React.FC = () => {
  const { setOnboardingStepResult, setOnboardingWizardComplete } = useWizardStore();

  // Define info slide data for better readability
  const infoSlides: Array<{
    id: string;
    title: string;
    description: string;
    imageUrl: string;
    showProgressBar?: boolean;
  }> = [
    {
      id: 'profile_resume',
      title: 'Your profile is your dating resume',
      description: 'YourMove AI chooses your best photos and gives actionable feedback to put your profile in the top 10%',
      imageUrl: step1Image,
      showProgressBar: false
    },
    {
      id: 'bio_importance',
      title: 'Studies show having a good bio can 4x your matches in online dating',
      description: "Profile writer takes your best features and spins it into a dating profile that will tell everyone you're witty and your dog thinks you're cute",
      imageUrl: step2Image,
      showProgressBar: false
    },
    {
      id: 'conversation_starters',
      title: 'Tired of staring at a match trying to come up with a line?',
      description: 'YourMove generates personalized openers for profiles or flirty replies to any conversation',
      imageUrl: step3Image,
      showProgressBar: false
    },
    {
      id: 'ai_photos',
      title: 'Enhance your photos with AI',
      description: 'Fire your photographer. YourMove creates studio quality photos of you with AI',
      imageUrl: step4Image,
      showProgressBar: false
    }
  ];

  // Define the intro slide separately for clarity
  const introSlide = {
    id: 'intro',
    type: 'intro' as const,
    headline: 'Dating apps are exhausting',
    // The bold sections will be handled by the renderDescription function in the IntroCard component
    description: 'YourMove helps you win at dating apps. So you can spend less time swiping and more time dating',
    content: null,
    showProgressBar: false,
    showLogo: true
  };

  // Create wizard slides without the intro slide
  const contentSlides = [
    ...infoSlides.map(slide => ({
      id: slide.id,
      type: 'info' as const,
      title: slide.title,
      description: slide.description,
      imageUrl: slide.imageUrl,
      content: null,
      showProgressBar: slide.showProgressBar
    })),
    // Question slides
    {
      id: WizardStepType.GENDER,
      type: 'question' as const,
      question: 'You are a ...',
      options: ['Man', 'Woman', 'Other'],
      content: null,
      showProgressBar: true
    },
    {
      id: WizardStepType.GENDER_OTHER,
      type: 'question' as const,
      question: 'Interested in ...',
      options: ['Women', 'Men', 'Both'],
      content: null,
      showProgressBar: true
    },
    {
      id: WizardStepType.APPS_USED,
      type: 'question' as const,
      question: 'Which apps do you use?',
      options: ['Hinge', 'Tinder', 'Bumble', 'CoffeeMeetsBagel', 'Raya', 'OkCupid', 'Other'],
      multiSelect: true,
      content: null,
      showProgressBar: true
    },
    {
      id: WizardStepType.LOOKING_FOR,
      type: 'question' as const,
      question: 'What are you looking for?',
      // Standardized relationship goal options
      options: ['something casual', 'long-term partner', 'soul mate', 'still figuring it out'],
      content: null,
      showProgressBar: true
    },
    {
      id: 'current_experience',
      type: 'question' as const,
      question: 'What is your current dating app experience like?',
      options: ['💔 Not getting matches', '😕 Some matches but they aren\'t my type', '🤷‍♂️ Enough matches but no dates', '🤩 Everything is great'],
      content: null,
      showProgressBar: true
    },
    {
      id: 'matches_getting',
      type: 'question' as const,
      question: 'How many matches are you getting?',
      options: ['😕 Less than 1 per day', '🙂 1-2 per day', '😊 3-7 per day', '🤩 8-15 per day', '🥵 More than I can handle'],
      content: null,
      showProgressBar: true
    },
    {
      id: 'matches_quality',
      type: 'question' as const,
      question: 'What about the quality of who you match with?',
      options: ['😑 Not my type at all', '🤷‍♂️ A few people I like but rarely', '👍 Good. I like a lot of my matches', '🥳 Great! Excited about everyone I match'],
      content: null,
      showProgressBar: true
    },
    {
      id: 'dating_experience',
      type: 'question' as const,
      question: 'How\'s your online dating experience going?',
      options: ['😕 Not really', '🤔 Could be better', '😊 Having a good time', '🎉 Amazing - wouldn\'t change anything'],
      content: null,
      showProgressBar: true
    },
    {
      id: 'tried_so_far',
      type: 'question' as const,
      question: 'What have you tried so far?',
      options: ['🤷 Nothing yet', '🧪 Boosts', '💰 Premium subscriptions', '📸 Photographers', '👵 Matchmakers', '🤯 Literally everything'],
      content: null,
      showProgressBar: true
    },
    {
      id: 'matches_wish',
      type: 'question' as const,
      question: 'How many matches do you wish you were getting?',
      options: ['😊 1-2 matches a week', '😊 1-3 matches a day', '😎 4-10 matches a day', '🤯 10+ matches a day'],
      content: null,
      showProgressBar: true
    },
    {
      id: 'motivation',
      type: 'question' as const,
      question: 'How motivated are you to hit that target?',
      options: ['💪 Very motivated, let\'s do this!', '🙌 I\'m quite motivated', '🤷 Curious, but not too motivated', '😕 Not motivated at all. C\'est la vie'],
      content: null,
      showProgressBar: true
    },
    // Add a loader slide after the last question
    {
      id: 'processing',
      type: 'loader' as const,
      titles: [
        'Analyzing your preferences...',
        'Generating recommendations...',
        'Optimizing your profile...',
        'Setting up your account...',
        'Preparing everything for you...'
      ],
      updateInterval: 1000,
      content: null,
      showProgressBar: false,
      showLogo: true
    },
    // Replace the features slide with a paywall slide using the PremiumV2 component
    {
      id: 'paywall',
      type: 'paywall' as const,
      productType: "chat_assistant" as ProductType, // Explicitly cast to ProductType
      content: null,
      showProgressBar: false,
      showLogo: false // Hide the logo for the paywall screen
    },
    // Add a placeholder "success" slide after the paywall
    {
      id: 'success',
      type: 'info' as const,
      title: 'You\'re All Set!',
      description: 'Your account is ready to use. Start exploring YourMove AI to improve your dating experience.',
      imageUrl: step4Image,
      content: null,
      showProgressBar: false,
      showLogo: true
    }
  ];

  // Combine intro slide with content slides
  const wizardSlides = [introSlide, ...contentSlides];

  const handleComplete = (responses: Record<string, any>) => {
    console.log('Onboarding completed!', responses);
    
    // Save all responses to the wizard store using the standard keys
    Object.entries(responses).forEach(([key, value]) => {
      setOnboardingStepResult(key, value);
    });
    
    // Mark onboarding as complete
    setOnboardingWizardComplete(true);
    
    // Redirect to the next-move page
    window.location.href = '/next-move';
  };

  return (
    <OnboardingWizard 
      slides={wizardSlides} 
      onComplete={handleComplete}
      startWithProgressBar={true}
    />
  );
}; 