export const MONTHLY_PRICE = 15;
export const ANNUAL_MONTHLY_PRICE = 6;
export const ANNUAL_PRICE = 72;

export const MONTHLY_PRICE_PREMIUM = 15;
export const ANNUAL_MONTHLY_PRICE_PREMIUM = 6;
export const ANNUAL_PRICE_PREMIUM = 72;

export const SALE_MONTHLY_PRICE = 23;
export const SALE_ANNUAL_MONTHLY_PRICE = 8;
export const SALE_ANNUAL_PRICE = 100;

export const AIPHOTOS_SUBSCRIBED = 15;
// export const AIPHOTOS_SUBSCRIBED_DECIMAL = 14.99
export const AIPHOTOS_UNSUBSCRIBED = 29;
// export const AIPHOTOS_UNSUBSCRIBED_DECIMAL = 28.99

export const SALE_PERCENTAGE = 40;

export const AIPHOTOS_BASIC_PRICE = 29;
export const AIPHOTOS_BASIC_PRICE_SUBSCRIBED = 22;
export const AIPHOTOS_PLUS_PRICE = 49;
export const AIPHOTOS_PLUS_PRICE_SUBSCRIBED = 37;
export const AIPHOTOS_PREMIUM_PRICE = 79;
export const AIPHOTOS_PREMIUM_PRICE_SUBSCRIBED = 59;

export const LOCKED_AIPHOTOS_PRICE = 20;

export const CONVERSION_FACTOR_MONTHLY = 4.5;
export const CONVERSION_FACTOR_ANNUAL = 1;
export const CONVERSION_FACTOR_AIPHOTOS = 1;

// For analytics calculations, rather than multiplying by conversion factors (which has led to bugs
// related to floats and integers), we now use a fixed value of 75 for all subscribers.
export const SUBSCRIBER_LTV = 75;

// Special pricing for abandon cart flow
export const ANNUAL_PRICE_ABANDON_CART = 35.99;  // Annual price of $35.99
export const MONTHLY_PRICE_ABANDON_CART = 2.99;   // Monthly price of $2.99
