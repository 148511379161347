import { PlanType } from "../../constants/payments";
import { useEffect } from "react";
import SubscriptionPaymentForm from "../payment/SubscriptionPaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import {
  Appearance,
  StripeElementsOptionsMode,
  loadStripe,
} from "@stripe/stripe-js";
import { useUIStore } from "../../stores/ui";
import { ProductType } from "../../models/payment";
import { MONTHLY_PRICE, ANNUAL_PRICE } from "../../pages/premium/paymentsConfig";
import { Dispatch, SetStateAction } from "react";

interface Props {
  planType: PlanType;
  redirectHandler: () => Promise<void>;
  email?: string;
  product: ProductType;
  discountCode?: string;
  payButtonVariant: string;
  setIsAbandonCartOpen?: Dispatch<SetStateAction<boolean>>;
}

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

export const SubscriptionForm = ({
  planType,
  redirectHandler,
  email = undefined,
  product = "chat_assistant",
  payButtonVariant,
  setIsAbandonCartOpen,
}: Props) => {
  const { setSubscriptionSuccess, setStopScroll, abTestGroup } = useUIStore();

  // When the subscription form is showing, we want to re-enable scrolling
  useEffect(() => {
    setStopScroll(false);
  }, []);

  const appearance: Appearance = {
    theme: "stripe",
  };

  const options: StripeElementsOptionsMode = {
    mode: "subscription",
    amount: planType === PlanType.Monthly 
      ? MONTHLY_PRICE * 100  // $15.00 = 1500 cents
      : ANNUAL_PRICE * 100,  // $72.00 = 7200 cents
    currency: "usd",
    appearance,
  };

  console.log('SubscriptionForm payButtonVariant:', payButtonVariant);

  return (
    <div className="bg-white rounded-lg border border-black p-4 w-[calc(100%-2rem)] md:w-[600px] mx-4 md:mx-0 mb-20">
      <Elements stripe={stripePromise} options={options}>
        <SubscriptionPaymentForm
          email={email}
          planType={planType}
          redirectSuffix="/"
          product={product}
          redirectHandler={redirectHandler}
          payButtonVariant={payButtonVariant}
        />
      </Elements>
    </div>
  );
};
