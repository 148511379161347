import { useEffect, useState } from "react";
import { FileUploadStep } from "../../components/wizard/FileUploadStep";
import { useWizardStore } from "../../stores/wizard";
import { useProfileStore } from "../../stores/profile";

type Props = {
  onContinueClick?: VoidFunction
  files?: Array<string>
  setFiles?: (files: Array<string>) => void
}

export const ProfileReviewUploadControl = ({ files = [], setFiles = undefined, onContinueClick = undefined }: Props) => {
  const { filesUploading, setFilesUploading } = useWizardStore();
  const { setReviewedProfile } = useProfileStore()

  const checkButtonEnabled = () => !filesUploading && files.length > 0

  useEffect(() => {
    setButtonEnabled(checkButtonEnabled())
  }, [filesUploading, files])

  const [buttonEnabled, setButtonEnabled] = useState(checkButtonEnabled)

  return (
    <div className="flex flex-col h-svh mb-4 w-full" style={{
      height: 'calc(100vh - 280px)'
    }}>
      <p className="mt-4 text-xl text-bold font-bold px-4">Review your entire profile</p>
      <div className="flex-grow flex flex-col justify-between">
        <div>
          <FileUploadStep
            filesUploading={filesUploading}
            setFilesUploading={setFilesUploading}
            alreadySetFiles={files}
            onFilesUploaded={(files: Array<string>) => {
              setReviewedProfile(null)
              setFiles?.(files);
              setButtonEnabled(true)
            }}
          />
          <p className="mx-4 mt-4 text-black text-center">
            select up to 12 screenshots of your entire dating profile
          </p>
        </div>
        <button
          disabled={!buttonEnabled}
          className={`flex items-center justify-center w-full py-3 font-semibold rounded-xl mb-4 ${buttonEnabled
            ? 'bg-brand-primary text-white'
            : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          onClick={buttonEnabled ? onContinueClick ?? undefined : undefined}
        >
          <p className="text-xl">Continue</p>
        </button>
      </div>
    </div>
  );
};

