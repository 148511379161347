import { useState, useEffect } from "react";
import { useAIPhotosStore } from "../../stores/aiphotos";
import { useAuthStore } from "../../stores/auth";
import { CrossIconTransparent } from "../../components/CrossIcon";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useAIPhotosUpload } from "./useAIPhotosUpload";
import { AIPhotosPlan } from "../../constants/payments";
import { AIPHOTOS_BASIC_PRICE, AIPHOTOS_PLUS_PRICE_SUBSCRIBED, AIPHOTOS_BASIC_PRICE_SUBSCRIBED, AIPHOTOS_PLUS_PRICE, AIPHOTOS_PREMIUM_PRICE, AIPHOTOS_PREMIUM_PRICE_SUBSCRIBED } from "../premium/paymentsConfig";
import { logEvent, logEventServerSide, useLogEvent } from "../../analytics";


type Props = {
  onContinue: (selectedPlan: AIPhotosPlan) => void;
};

export const PreviewPhotosStep = ({ onContinue }: Props) => {
  const { filesToUpload, removeFile, aiPhotosEmail } = useAIPhotosStore();
  const { isSubscribed } = useAuthStore();
  const [selectedPackage, setSelectedPackage] = useState<AIPhotosPlan>(AIPhotosPlan.Plus);

  useEffect(() => {
    logEvent('results', 'ai_photos', aiPhotosEmail, {}, 'funnel');
  }, []);

  const handlePackageSelect = (packageName: AIPhotosPlan) => {
    setSelectedPackage(packageName);
    logEvent('purchase_batch_unlock', 'ai_photos', aiPhotosEmail, {}, 'funnel');
    onContinue(packageName);
  };

  const handleContinueClick = () => {
    logEvent('purchase_batch_unlock', 'ai_photos', aiPhotosEmail, {}, 'funnel');
    onContinue(selectedPackage);
  };

  const { getRootProps, getInputProps } = useAIPhotosUpload(undefined, false);

  return (
    <div className="flex flex-col w-full px-2 overflow-x-hidden">
      <div className="w-full bg-white border-2 border-black rounded-xl shadow-lg">
        <h1 className="text-left text-green-600 p-3 rounded-t-xl bg-green-100 text-sm font-semibold px-6">
          Congratulations! Your photos are ready to be processed
        </h1>
        <div
          className="mb-4 mt-4 overflow-x-auto px-4"
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        >
          <div className="flex space-x-4 after:w-4 after:flex-shrink-0">
            {filesToUpload.map((file, index) => (
              <div
                key={index}
                className="w-[120px] h-[120px] flex-shrink-0 relative"
              >
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Uploaded Preview ${index + 1}`}
                  className="w-full h-full object-cover rounded-lg"
                />
                <button
                  onClick={() => removeFile(index)}
                  className="absolute top-0 right-0 p-1 rounded-full shadow-md"
                >
                  <CrossIconTransparent />
                </button>
              </div>
            ))}
            <div
              {...getRootProps()}
              className="w-[120px] h-[120px] flex-shrink-0 border border-brand-primary rounded-lg flex items-center justify-center cursor-pointer"
            >
              <input {...getInputProps()} />
              <PlusIcon
                style={{ height: 40 }}
                className="text-brand-primary"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <div className="mb-2 flex items-center justify-between">
          <h2 className="text-lg font-bold">Select package</h2>
          {isSubscribed && <span className="h-6 pt-0.5 px-2 text-sm font-semibold rounded bg-[#FADFDE] text-brand-primary">
            Premium Discount
          </span>}
        </div>

        <div className="flex flex-col space-y-2 mb-2">
          <div
            onClick={() => handlePackageSelect(AIPhotosPlan.Basic)}
            className={`border-2 bg-white ${selectedPackage === AIPhotosPlan.Basic
              ? "border-red-500"
              : "border-white"
              } rounded-2xl p-3 pt-2 cursor-pointer`}
          >
            <div className="flex justify-between items-center mb-1">
              <h3 className="font-semibold text-base sm:text-base">Basic</h3>
              <p className={`font-semibold text-base sm:text-base relative ${selectedPackage === AIPhotosPlan.Basic && "text-red-500"}`}>
                {isSubscribed ? `$${AIPHOTOS_BASIC_PRICE_SUBSCRIBED}` : `$${AIPHOTOS_BASIC_PRICE}`}
                {isSubscribed && <span className="absolute font-light top-full right-0 text-sm line-through text-gray-500">
                  {`$${AIPHOTOS_BASIC_PRICE}`}
                </span>}
              </p>
            </div>
            <ul className="list-disc pl-4 text-xs sm:text-sm text-black-600 space-y-1">
              <li className="list-item flex items-center gap-2">
                <span>24 Photos</span>
              </li>
              <li className="list-item flex items-center gap-2">
                <span>6 styles + settings</span>
              </li>
              <li className="list-item flex items-center gap-2">
                <span>Ready in 4 hours</span>
              </li>
            </ul>
          </div>

          <div
            onClick={() => handlePackageSelect(AIPhotosPlan.Plus)}
            className={`border-2 bg-white ${selectedPackage === AIPhotosPlan.Plus
              ? "border-red-500"
              : "border-white"
              } rounded-2xl p-3 pt-2 cursor-pointer`}
          >
            <div className="flex justify-between items-center mb-1">
              <div className="flex items-baseline gap-2">
                <h3 className="font-semibold text-base sm:text-base">Plus</h3>
                <span className="text-brand-primary text-xxs font-semibold translate-y-[-1px]">
                  MOST POPULAR 🔥
                </span>
              </div>
              <p className={`font-semibold text-base sm:text-base relative ${selectedPackage === AIPhotosPlan.Plus && "text-red-500"}`}>
                {isSubscribed ? `$${AIPHOTOS_PLUS_PRICE_SUBSCRIBED}` : `$${AIPHOTOS_PLUS_PRICE}`}
                {isSubscribed && <span className="absolute font-light top-full right-0 text-sm line-through text-gray-500">
                  {`$${AIPHOTOS_PLUS_PRICE}`}
                </span>}
              </p>
            </div>
            <ul className="list-disc pl-4 text-xs sm:text-sm text-black-600 space-y-1">
              <li className="list-item flex items-center gap-2">
                <span>100 photos</span>
              </li>
              <li className="list-item flex items-center gap-2">
                <span>25 styles + settings</span>
              </li>
              <li className="list-item flex items-center gap-2">
                <span>Ready in 3 hours</span>
              </li>
            </ul>
          </div>

          <div
            onClick={() => handlePackageSelect(AIPhotosPlan.Premium)}
            className={`border-2 bg-white ${selectedPackage === AIPhotosPlan.Premium
              ? "border-red-500"
              : "border-white"
              } rounded-2xl p-3 pt-2 cursor-pointer`}
          >
            <div className="flex justify-between items-center mb-1">
              <div className="flex items-baseline gap-2">
                <h3 className="font-semibold text-base sm:text-base">Premium</h3>
                <span className="text-gray-500 text-xxs font-semibold translate-y-[-1px]">
                  BEST VALUE ⭐
                </span>
              </div>
              <p className={`font-semibold text-base sm:text-base relative ${selectedPackage === AIPhotosPlan.Premium && "text-red-500"}`}>
                {isSubscribed ? `$${AIPHOTOS_PREMIUM_PRICE_SUBSCRIBED}` : `$${AIPHOTOS_PREMIUM_PRICE}`}
                {isSubscribed && <span className="absolute font-light top-full right-0 text-sm line-through text-gray-500">
                  {`$${AIPHOTOS_PREMIUM_PRICE}`}
                </span>}
              </p>
            </div>
            <ul className="list-disc pl-4 text-xs sm:text-sm text-black-600 space-y-1">
              <li className="list-item flex items-center gap-2">
                <span>200 Photos</span>
              </li>
              <li className="list-item flex items-center gap-2">
                <span>50 styles + settings</span>
              </li>
              <li className="list-item flex items-center gap-2">
                <span>Ready in 2 hours</span>
              </li>
            </ul>
          </div>
        </div>

        <button
          disabled={!selectedPackage || filesToUpload.length === 0}
          onClick={handleContinueClick}
          className={`mt-8 mb-8 flex items-center justify-center w-full py-3 rounded-md font-semibold bg-brand-primary text-white ${filesToUpload.length === 0 ? "opacity-50 cursor-not-allowed" : ""
            }`}
        >
          Get your Profile Photos
        </button>

        {/* {!isSubscribed && (
          <div className="w-full text-red-500 py-3 font-semibold text-center">
            Get Premium, save 50% on AI Photos
          </div>
        )} */}
      </div>
    </div>
  );
};
