export enum MessageType {
  Open = "Open",
  Reply = "Reply",
  Close = "Close",
}

export enum MessageSubType {
  Starter = "Starter",
  ReEngage = "Re-Engage",
  AskOut = "AskOut",
  Reject = "Reject",
}

export enum ChatType {
  Reply = "reply",
  Opener = "opener",
  ReEngage = "reengage",
  Closer = "closer",
  Reject = "reject",
}

export const subTypeToChatType = {
  [MessageSubType.Starter]: ChatType.Opener,
  [MessageSubType.ReEngage]: ChatType.ReEngage,
  [MessageSubType.AskOut]: ChatType.Closer,
  [MessageSubType.Reject]: ChatType.Reject,
};

export const MessageTypeSubTypeMappings = {
  Open: [MessageSubType.Starter, MessageSubType.ReEngage],
  Reply: [],
  Close: [MessageSubType.AskOut, MessageSubType.Reject],
};

export enum MessageStyle {
  Flirty = "😉 Flirty",
  Feisty = "😝 Feisty",
  Friendly = "😊 Friendly",
  Funny = "🤭 Funny",
  Formal = "👔 Formal",
}

export enum Gender {
  Male = "🧔 Male",
  Female = "👩 Female",
  Other = "Other",
}

export const MessageInputConfigurations = {
  Open: {
    Starter: {
      headline: "Upload a screenshot of a profile:",
      placeholder: "...or enter a topic for a conversation starter",
      screenshot: "Upload from Gallery",
    },
    "Re-Engage": {
      headline: "Re-ignite a ghosted conversation",
      placeholder: null,
      screenshot: null,
    },
  },
  Reply: {
    headline: "Upload a screenshot of a chat:",
    placeholder: "...or paste in a message you received",
    screenshot: "Upload from Gallery",
  },
  Close: {
    AskOut: {
      headline: "Upload a screenshot of a chat:",
      placeholder: "...or paste in a message you received",
      screenshot: "Upload from Gallery",
    },
    Reject: {
      headline: "End conversations without ghosting",
      placeholder: null,
      screenshot: null,
    },
  },
};

export enum MessageAuthorType {
  // Generated by the Appl
  Generated = "Generated",
  // Message was written by the user
  User = "User",
}

// whether it was an image or text
export enum ChatRequestType {
  Text = "Text",
  Image = "Image",
}
