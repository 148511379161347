import { usePostHog, useFeatureFlagVariantKey } from "posthog-js/react";
import { SubscriptionPlanHStyle } from "./SubscriptionPlan";
import {
  MONTHLY_PRICE,
  ANNUAL_MONTHLY_PRICE,
  MONTHLY_PRICE_PREMIUM,
  ANNUAL_MONTHLY_PRICE_PREMIUM,
} from "./../../../pages/premium/paymentsConfig";
import { usePaymentStore } from "../../../stores/payment";
import { useLocation } from "react-router-dom";

type Props = {
  onMonthlyPress: VoidFunction;
  onAnnualPress: VoidFunction;
};

export const PremiumProducts = ({ onMonthlyPress, onAnnualPress }: Props) => {
  const { selectedPlan, setSelectedPlan } = usePaymentStore();
  const location = useLocation();
  
  // Check if we're in the onboarding flow
  const isOnboardingFlow = location.pathname.includes('/start2');
  
  // Force 'control' variant for onboarding flow, otherwise use PostHog flag
  const newPayButtonLayout = isOnboardingFlow 
    ? 'control' 
    : useFeatureFlagVariantKey('pay-button-new-layout');
    
  const showPremiumPrices = useFeatureFlagVariantKey('premium-prices-new') === 'test';

  // const showPremiumPrices = postHog?.getFeatureFlag("premium-prices-new") === "test";
  // const additionalDescription = showPremiumPrices ? "Save 60%" : "Save 64%";
  const additionalDescription = "Save 64%";
  // const monthlyPrice = showPremiumPrices ? MONTHLY_PRICE_PREMIUM : MONTHLY_PRICE;
  // const monthlyAnnualPrice = showPremiumPrices ? ANNUAL_MONTHLY_PRICE_PREMIUM : ANNUAL_MONTHLY_PRICE;
  const monthlyPrice = MONTHLY_PRICE;
  const monthlyAnnualPrice = ANNUAL_MONTHLY_PRICE;

  const handlePlanClick = (plan: "annual" | "monthly") => {
    setSelectedPlan(plan);
  };

  const handleGetStarted = () => {
    if (selectedPlan === "annual") {
      onAnnualPress();
    } else {
      onMonthlyPress();
    }
  };

  if (newPayButtonLayout === "test") {
    // Treatment version - identical to control for now
    return (
      <div className="flex flex-col p-4">
        {/* <p className="text-center text-sm text-white">[Treatment Variant]</p> */}
        <div 
          className={`rounded-[20px] overflow-hidden cursor-pointer relative mb-3 ${
            selectedPlan === "annual" 
              ? "p-[2px] bg-gradient-to-r from-[#E85C65] to-[#952057]" 
              : "border-2 border-gray-300"
          }`}
          onClick={() => handlePlanClick("annual")}
        >
          <div className="rounded-[18px] bg-white overflow-hidden">
            <div className="bg-gradient-to-r from-[#E85C65] to-[#952057] text-white py-1 text-xs font-medium text-center w-full">
              Save 64%
            </div>
            <div className="px-4 pt-2 pb-4">
              <div className="flex justify-between items-start">
                <h3 className="text-base font-bold text-[#1F1F1F]">Annual Plan</h3>
                <div className="text-base font-bold text-[#1F1F1F]">$1.50/wk</div>
              </div>
              <div className="text-xs text-gray-500 mt-1">12 mo • $71.99</div>
            </div>
          </div>
        </div>
        <div 
          className={`rounded-[20px] p-4 cursor-pointer border-2 ${
            selectedPlan === "monthly" 
              ? "border-[#410B13] bg-white" 
              : "border-gray-300"
          }`}
          onClick={() => handlePlanClick("monthly")}
        >
          <div className="flex justify-between items-center">
            <h3 className="text-base font-bold text-[#1F1F1F]">Monthly Plan</h3>
            <div className="text-base font-bold text-[#1F1F1F]">$3.75/wk</div>
          </div>
        </div>

        {selectedPlan && (
          <button
            className="w-full py-4 bg-[#D93A3A] text-white text-base font-semibold rounded-[20px] mt-4 mb-2"
            onClick={handleGetStarted}
          >
            Continue
          </button>
        )}
      </div>
    );
  }

  // Control version - current hinge style
  return (
    <div className="flex flex-col p-4">
      {/* <p className="text-center text-sm text-red-500">[Control Variant]</p> */}
      {/* <p className="text-center text-sm text-red">[control Variant]</p> */}

      <div className="flex space-x-2">
        <div
          className="flex-1 cursor-pointer"
          onClick={() => handlePlanClick("annual")}
        >
          <SubscriptionPlanHStyle
            title="1 year"
            price={monthlyAnnualPrice}
            popular
            topLabel={additionalDescription}
            fullPrice={monthlyPrice}
            isSelected={selectedPlan === "annual"}
          />
        </div>
        <div
          className="flex-1 cursor-pointer"
          onClick={() => handlePlanClick("monthly")}
        >
          <SubscriptionPlanHStyle
            title="1 month"
            price={monthlyPrice}
            topLabel="Flexible"
            isSelected={selectedPlan === "monthly"}
          />
        </div>
      </div>
      <button
        className="w-full py-3 bg-[#410B13] text-white rounded-lg shadow-lg mt-4 mb-4 font-medium text-base"
        onClick={handleGetStarted}
      >
        Get Started
      </button>
    </div>
  );
};

/* Commenting out original control version
return (
  <div className="flex flex-col p-4">
    <div className="flex space-x-2">
      <div
        className="flex-1 cursor-pointer"
        onClick={() => handlePlanClick("annual")}
      >
        <SubscriptionPlan
          title="Annual"
          price={monthlyAnnualPrice}
          fullPrice={monthlyPrice} // Pass the monthly price as the full price
          additionalDescription={additionalDescription}
          isSelected={selectedPlan === "annual"}
        />
      </div>
      <div
        className="flex-1 cursor-pointer"
        onClick={() => handlePlanClick("monthly")}
      >
        <SubscriptionPlan
          title="Monthly"
          price={monthlyPrice}
          isSelected={selectedPlan === "monthly"}
        />
      </div>
    </div>
    {/* <p className="text-xs text-white font-ultralight text-center mt-2">
      7-day money back guarantee
    </p> */
//     <button
//       className="w-full py-3 bg-[#410B13] text-white rounded-lg shadow-lg mt-4 mb-4 font-medium text-base"
//       onClick={handleGetStarted}
//     >
//       Get Started
//     </button>
//   </div>
// );
// */
