import { AIPhotosWizard } from "./AIPhotosWizard";
import { AIPhotosWizardStep, useAIPhotosStore } from "../../stores/aiphotos";
import { Loading } from "../../components/Loading";
import { AIPhotosResults } from "./AIPhotosResults";
import { AIPhotosLanding, AIPhotosLandingTreatment } from "./AIPhotosLanding";
import { useEffect, useState } from "react";
import { UnlockAIPhotosModal } from "../../components/modals/UnlockAIPhotosModal";
import toast from "react-hot-toast";
import { AIPhotosProcessingStatus, useAIPhotosTask } from "./useAIPhotosTask";
import { AIPhotosPlan } from "../../constants/payments";
import { useSearchParams } from "react-router-dom";
import { useUserStore } from "../../stores/user";
import { useFeatureFlagVariantKey } from "posthog-js/react";

export type AIPhotoBatch = {
  email: string;
  title: string;
  photos: string[];
  tuneId: number;
  status: string;
  createdAt: string;
  outputUrls?: string[];
  lockedPhotos?: number;
};

type ExperimentVariant = "control" | "treatment";

export const AIPhotos = () => {
  const [variantLoaded, setVariantLoaded] = useState(false);
  const [fallbackTriggered, setFallbackTriggered] = useState(false);
  const [openUnlockAIPhotosModal, setOpenUnlockAIPhotosModal] = useState(false);
  const [showLandingPage, setShowLandingPage] = useState(true);
  const [loadingTitles, setLoadingTitles] = useState<string[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<AIPhotosPlan>(
    AIPhotosPlan.Plus
  );
  const [searchParams] = useSearchParams();
  const experimentVariant = useFeatureFlagVariantKey(
    "new-photo-flow"
  ) as ExperimentVariant;

  useEffect(() => {
    if (experimentVariant !== undefined && experimentVariant !== null) {
      setVariantLoaded(true);
    }
  }, [experimentVariant]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!variantLoaded) {
        setFallbackTriggered(true);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [variantLoaded]);

  console.log("Current experiment variant:", experimentVariant);

  const {
    age,
    gender,
    ethnicity,
    eyeColor,
    aiPhotosWizardComplete,
    setAIPhotosWizardComplete,
    generatingAIPhotos,
    setGeneratingAIPhotos,
    clearFilesToUpload,
    setAIPhotosWizardStep,
    aiPhotosEmail,
    setAIPhotosEmail,
    bypassPaywall,
    setBypassPaywall,
  } = useAIPhotosStore();

  const { isCreator } = useUserStore();
  const { generateAIPhotos, aiPhotosProcessingStatus } =
    useAIPhotosTask(selectedPlan);
  const { filesToUpload } = useAIPhotosStore();
  const hasAllRequiredFields =
    experimentVariant === "treatment"
      ? gender !== "" && aiPhotosEmail !== ""
      : age !== "" &&
        gender !== "" &&
        ethnicity !== "" &&
        eyeColor !== "" &&
        aiPhotosEmail !== "";

  useEffect(() => {
    if (hasAllRequiredFields && !searchParams.get("is_retrieve")) {
      setAIPhotosWizardStep(AIPhotosWizardStep.UPLOAD_IMAGES);
    }
  }, []);

  useEffect(() => {
    if (aiPhotosProcessingStatus === AIPhotosProcessingStatus.UPLOADING) {
      setLoadingTitles(["Uploading your photos, please wait..."]);
    } else if (
      aiPhotosProcessingStatus === AIPhotosProcessingStatus.CREATING_REQUEST
    ) {
      setLoadingTitles(["Adding your photos to the queue, please wait..."]);
    } else if (
      aiPhotosProcessingStatus === AIPhotosProcessingStatus.PROCESSING
    ) {
      setLoadingTitles(["Processing your photos, please wait..."]);
    } else if (aiPhotosProcessingStatus === AIPhotosProcessingStatus.TRAINING) {
      setLoadingTitles([
        "Training your photos - check your email for confirmation. Can't find it? Check your spam folder or contact support@yourmove.ai",
      ]);
    } else if (aiPhotosProcessingStatus === AIPhotosProcessingStatus.COMPLETE) {
      setLoadingTitles([
        "Photos will be ready in 3 to 5 hours, and will arrive in your email. Can't find it? Check your spam folder or contact support@yourmove.ai",
      ]);
    }
  }, [aiPhotosProcessingStatus]);

  useEffect(() => {
    const emailRef = searchParams.get("email_ref");
    const isRetrieve = searchParams.get("is_retrieve");
    const bypass = searchParams.get("bypass_paywall");

    if (bypass === "1" && bypassPaywall !== 0) {
      setBypassPaywall(1);
    }

    if (emailRef) {
      localStorage.setItem("email", emailRef);
      setAIPhotosEmail(emailRef);
    }

    if (isRetrieve === "true") {
      setShowLandingPage(false);
      setAIPhotosWizardComplete(true);
      if (emailRef) {
        toast.success(`Retrieving your photos...`);
      } else {
        toast.error(
          "Email parameter is required to retrieve photos - please contact support@yourmove.ai if you need assistance"
        );
      }
    }
  }, [searchParams]);

  const startProcessingPhotos = async () => {
    setGeneratingAIPhotos(true);
    setAIPhotosWizardComplete(true);
    try {
      await generateAIPhotos();
      clearFilesToUpload();
    } catch (error) {
      throw error;
    } finally {
      setGeneratingAIPhotos(false);
    }
  };

  const onPaymentSuccessful = async () => {
    try {
      setOpenUnlockAIPhotosModal(false);
      await startProcessingPhotos();
    } catch (error) {
      console.error("Error creating AI photos request:", error);
      toast.error(
        error instanceof Error
          ? error.message
          : "An unexpected error occurred during payment processing."
      );
    }
  };

  const onWizardComplete = async (selectedPlan: AIPhotosPlan) => {
    setSelectedPlan(selectedPlan);
    if (filesToUpload.length > 0) {
      if (isCreator) {
        console.log(`isCreator: ${isCreator} SKIPPING PAYMENT`);
        onPaymentSuccessful();
      } else if (bypassPaywall === 1) {
        onPaymentSuccessful();
        setBypassPaywall(0);
      } else {
        setOpenUnlockAIPhotosModal(true);
      }
    } else {
      toast.error("Please upload at least one photo to continue");
    }
  };

  if (!variantLoaded && !fallbackTriggered) {
    return null;
  }

  const finalVariant = fallbackTriggered && !variantLoaded ? "control" : experimentVariant;

  const showResults =
    !generatingAIPhotos &&
    aiPhotosWizardComplete &&
    (searchParams.get("is_retrieve") === "true" || (
      finalVariant === "treatment"
        ? gender !== "" && aiPhotosEmail !== ""
        : age !== "" && gender !== "" && ethnicity !== "" && eyeColor !== "" && aiPhotosEmail !== ""
    ));

  return (
    <>
      {showLandingPage ? (
        finalVariant === "treatment" ? (
          <AIPhotosLandingTreatment
            onNextPress={() => setShowLandingPage(false)}
          />
        ) : (
          <AIPhotosLanding onNextPress={() => setShowLandingPage(false)} />
        )
      ) : generatingAIPhotos ? (
        <Loading titles={loadingTitles} />
      ) : showResults ? (
        <AIPhotosResults experimentVariant={finalVariant} />
      ) : (
        <AIPhotosWizard
          onBack={() => setShowLandingPage(true)}
          onWizardComplete={onWizardComplete}
          experimentVariant={finalVariant}
        />
      )}
      <UnlockAIPhotosModal
        open={openUnlockAIPhotosModal}
        setOpen={setOpenUnlockAIPhotosModal}
        redirectHandler={onPaymentSuccessful}
        selectedPlan={selectedPlan}
      />
    </>
  );
};
