import { AIPhotoBatch } from './AIPhotos'
import LoadingSpinner from '../../components/LoadingSpinner'

type Props = {
  loading: boolean
  batches: AIPhotoBatch[]
  onBatchClick: (id: number) => void
}
export const AIPhotosBatches = ({ batches, onBatchClick, loading }: Props) => (
  <>
    {loading ? (
      <LoadingSpinner containerClassName="mt-10" />
    ) : (
      <div className="flex-1 mt-4 flex flex-col items-center">
        {batches.sort((batchOne, batchTwo) => new Date(batchTwo.createdAt).getTime() - new Date(batchOne.createdAt).getTime()).map((batch, index) => (
          <div
            key={index}
            className="bg-gray-200 rounded-2xl w-full max-w-[450px] h-[450px] flex justify-center items-center mb-6 border-2 border-black relative"
            onClick={() => batch.status === 'processed' && onBatchClick(index)}
          >
            <>
              {batch.status === 'requested' || batch.status === 'processing' ? (
                <div className="flex flex-col justify-center items-center w-full h-full aspect-square">
                  <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-l-4 border-gray-600 mb-4"></div>
                  <p className="text-gray-800 text-center px-6 pt-4">
                    Photos take 3 to 5 hours to process. You will receive an email once they're ready. Can't find it? Check your spam folder or contact support@yourmove.ai
                  </p>
                </div>
              ) : (
                <>
                  {batch.outputUrls && batch.outputUrls.length > 0 && (
                    <img
                      src={batch.outputUrls[0]}
                      alt={`Profile Photos #${index}`}
                      className="w-full h-full object-cover rounded-2xl cursor-pointer"
                    />
                  )}
                </>
              )}
              <div className="absolute bottom-4 left-4">
                <p className={`${batch.status === 'requested' || batch.status === 'processing' || batch.status === 'pending' ? "text-gray-800" : "text-white"} text-shadow-md font-bold text-2xl`}>
                  Photo set #{batches.length - index}
                </p>
                {batch.status === 'pending' && <p className="text-base font-medium text-gray-800">Coming in 3-5 hours</p>}
              </div>
            </>
          </div>
        ))}
      </div>
    )}
  </>
)