import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Loading } from "../../components/Loading";
import { PremiumProductsCarousel } from "../../components/payment/paywalls/PremiumProductsCarousel";
import { PremiumProducts } from "../../components/payment/paywalls/PremiumProducts";
import { PlanType } from "../../constants/payments";
import { EventParams, logEvent, useLogEvent } from "../../analytics";
import { Success } from "../../components/Success";
import { PremiumState } from "../../models/payment";
import { useAuthStore } from "../../stores/auth";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import {
  ANNUAL_PRICE,
  // ANNUAL_PRICE_PREMIUM,
  // CONVERSION_FACTOR_ANNUAL,
  // CONVERSION_FACTOR_MONTHLY,
  MONTHLY_PRICE,
  // MONTHLY_PRICE_PREMIUM,
  SUBSCRIBER_LTV,
} from "./paymentsConfig";
import { SubscriptionForm } from "../../components/premium/SubscriptionForm";
import { useWizardStore } from "../../stores/wizard";
import { auth } from "../../firebase";
import { checkUserSubscription } from "../../queries";
import { sleep } from "../../utils";
import toast from "react-hot-toast";
import { YourMoveHeader } from "../../components/YourMoveHeader";
import { usePaymentStore } from "../../stores/payment";
import { FamewallReviews } from "../FamewallReviews";
import { usePostHog, useFeatureFlagVariantKey } from "posthog-js/react";
import { addToMailingList, updateMailcoachSubscriber } from "../../queries";
import { FeaturesModal } from "../../components/modals/FeaturesModal";
import { ProductType } from "../../models/payment";
import { PremiumFeatures } from "../../components/payment/paywalls/PremiumFeatures";
import { BeforeYouGoModal } from "../../components/modals/BeforeYouGoModal";
import { AbandonCartModal } from "../../components/modals/AbandonCartModal";
import { shouldShowBeforeYouGoModal, setBeforeYouGoModalShown, shouldShowAbandonCartModal, setAbandonCartModalShown } from "../../stores/utils";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CarouselV2 } from "../../components/payment/paywalls/CarouselV2";
import React from "react";
import { useUIStore } from "../../stores/ui";

export const PremiumV2 = ({
  product = "chat_assistant",
  onDismiss,
  onComplete,
  showNoThanks = false,
  is_onboarding = false,
}: PremiumState) => {
  const {
    setAuthModalIsOpen,
    setIsSubscribed,
    setShowAuthSubscriptionDisclaimer,
    setAuthModalDismissable,
  } = useAuthStore();
  const { isSubscribed } = useAuthStore();
  const { planType, setPlanType, selectedPlan, discountCode, setIsAbandonCartFlow } =
    usePaymentStore();
  const { setProfileWriterWizardComplete, email } = useWizardStore();

  const postHog = usePostHog();
  
  // Feature flag handling for onboarding mode
  // When in onboarding, directly set values to 'control' without calling PostHog
  // When not in onboarding, use normal PostHog feature flag behavior
  // Pay button variant
  const payButtonVariant = is_onboarding 
    ? 'control' 
    : String(useFeatureFlagVariantKey('pay-button-new-layout') || 'control');
    
  // Before you go modal
  const beforeYouGoFlag = is_onboarding 
    ? 'control' 
    : postHog?.getFeatureFlag('before-you-go');
  const beforeYouGoModalTestEnabled = beforeYouGoFlag === 'test';
  
  // Abandon cart modal
  const abandonCartFlag = is_onboarding 
    ? 'control' 
    : useFeatureFlagVariantKey('abandon-cart-popup');
  const abandonCartTestEnabled = abandonCartFlag === 'test';
  
  // Pricing carousel
  const carouselFlag = is_onboarding 
    ? 'control' 
    : useFeatureFlagVariantKey('pricing-carousel');
  const carouselTestEnabled = carouselFlag === 'test';
  
  // Show sale flag
  const featureFlag = is_onboarding
    ? 'control'
    : postHog?.getFeatureFlag("show-sale");

  // If variant is 'test', use white bg, otherwise use brand-primary
  const bgClass = !payButtonVariant || payButtonVariant === 'control' 
    ? 'bg-brand-primary' 
    : 'bg-white';

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [isLoading] = useState(false);
  const emailRef = useRef(email);

  // const showPremiumPrices = postHog?.getFeatureFlag("premium-prices-new") === "test";
  // const showPremiumPrices = false; // Use regular prices since they're now the same

  useEffect(() => {
    logEvent("premium_page_view", product, email, {}, "funnel");
  }, [product]);

  useEffect(() => {
    if (paymentSuccess) {
      setTimeout(() => {
        if (!auth?.currentUser) {
          setShowAuthSubscriptionDisclaimer(true);
          setAuthModalDismissable(false);
          setAuthModalIsOpen(true);
        }
        onDismiss?.();
        onComplete?.();
      }, 2750);
    }
  }, [paymentSuccess]);

  useEffect(() => {
    emailRef.current = email;
  }, [email]);

  const onMonthlyPress = () => {
    setPlanType(PlanType.Monthly);
    setIsAbandonCartFlow(false);
    const params: EventParams = {
      amount: `${MONTHLY_PRICE}`,
      payment_type: "monthly",
    };

    if (featureFlag !== undefined) {
      params["show_sale"] = featureFlag;
    }

    if (window.location.pathname === "/welcome") {
      // TODO: handle via logEventServerSide
    }
  };

  const onAnnualPress = () => {
    setPlanType(PlanType.Yearly);
    setIsAbandonCartFlow(false);
    const params: EventParams = {
      amount: `${ANNUAL_PRICE}`,
      payment_type: "annual",
    };

    if (featureFlag !== undefined) {
      params["show_sale"] = featureFlag;
    }

    if (window.location.pathname === "/welcome") {
      // TODO: handle via logEventServerSide
    }
  };

  const logPurchaseEvent = async () => {
    console.log("ANALYTICS:: logPurchaseEvent");
    const amount: number =
      planType === PlanType.Monthly
        ? MONTHLY_PRICE  // Use regular price
        : ANNUAL_PRICE;  // Use regular price
      // planType === PlanType.Monthly
      //   ? showPremiumPrices
      //     ? MONTHLY_PRICE_PREMIUM
      //     : MONTHLY_PRICE
      //   : showPremiumPrices
      //   ? ANNUAL_PRICE_PREMIUM
      //   : ANNUAL_PRICE;
    const value: number = SUBSCRIBER_LTV;
    const currency = "USD";
    const { currentProduct } = useUIStore.getState();
    
    const params: EventParams = {
      amount,
      payment_type: planType === PlanType.Monthly ? "monthly" : "annual",
      value,
      currency,
      product: currentProduct, // Include product directly in params
    };
    
    if (featureFlag !== undefined) {
      params["show_sale"] = featureFlag;
    }
    
    logEvent("purchase_activate", currentProduct, email, params, "payment");
    logEvent("purchase_activate_subscribe", currentProduct, email, params, "payment");

    if (planType === PlanType.Monthly)
      logEvent("purchase_activate_monthly", currentProduct, email, params, "payment");
    else 
      logEvent("purchase_activate_annual", currentProduct, email, params, "payment");
    updateMailcoachSubscriber(["premium"]);
  };

  const onBackPress = () => {
    if (shouldShowAbandonCartModal(abandonCartTestEnabled)) {
      setAbandonCartModalShown();
      setIsAbandonCartOpen(true);
    } else {
      setPlanType(undefined);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProduct, setModalProduct] = useState<ProductType | undefined>(
    undefined
  );
  const [isBeforeYouGoOpen, setIsBeforeYouGoOpen] = useState(false);
  const [isAbandonCartOpen, setIsAbandonCartOpen] = useState(false);

  const openFeaturesModal = (productKey: ProductType) => {
    setModalProduct(productKey);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalProduct(undefined);
  };

  const handleDismiss = () => {
    // Normal behavior for all flows (onboarding or not)
    if (beforeYouGoModalTestEnabled && shouldShowBeforeYouGoModal(beforeYouGoModalTestEnabled)) {
      setIsBeforeYouGoOpen(true);
    } else {
      onDismiss?.();
    }
  };

  const handleBeforeYouGoClose = () => {
    setIsBeforeYouGoOpen(false);
    setBeforeYouGoModalShown();
    onDismiss?.();
  };

  return paymentSuccess ? (
    <div className="text-center mt-8">
      <Success title="You have successfully subscribed to Premium." />
    </div>
  ) : isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="relative w-screen left-1/2 right-1/2 -mx-[50vw] bg-white flex justify-center">

        <Helmet>
          <meta
            name="description"
            content="Unlimited access to YourMove AI suite of tools for online dating"
          />
        </Helmet>
        <div className={`w-full overflow-y-auto flex flex-col h-full`}>
          {planType && onBackPress && (
            <div className="flex-shrink-0">
              <YourMoveHeader onClose={onBackPress} />
            </div>
          )}

          <div className="flex-grow flex flex-col">
            {isSubscribed ? (
              <div className="text-center mt-8 flex-grow">
                <div className="flex items-center justify-center mb-4">
                  <CheckCircleIcon className="text-white h-16 w-16 stroke" />
                </div>
                <h3>You are already subscribed to YourMove premium.</h3>
                
                <button 
                  className="mt-4 text-white bg-blue-500 px-4 py-2 rounded"
                  onClick={() => {
                    window.location.href = "/next-move";
                  }}
                >
                  Continue
                </button>
              </div>
            ) : planType ? (
              <div className="flex-grow flex flex-col items-center">
                <SubscriptionForm
                  planType={planType}
                  email={emailRef.current}
                  product={product}
                  payButtonVariant={payButtonVariant}
                  redirectHandler={async () => {
                    let iterations = 0;
                    let isSubscribed = false;
                    while (isSubscribed === false && iterations < 10) {
                      const isSubscribedResponse = await checkUserSubscription(
                        emailRef.current
                      );
                      if (isSubscribedResponse.data.isSubscribed) {
                        isSubscribed = true;
                        await logPurchaseEvent();
                        setTimeout(() => {
                          setIsSubscribed(true);
                          setPaymentSuccess(true);
                        }, 500);
                        const currentProduct = useUIStore.getState().currentProduct;
                        addToMailingList(emailRef.current, currentProduct);
                        break;
                      } else {
                        await sleep(1000);
                        iterations++;
                      }
                    }

                    if (!isSubscribed) {
                      toast.error(
                        "You Subscribed but there was an error getting your subscription. Automatically refreshing page"
                      );
                      logPurchaseEvent();
                      setTimeout(() => {
                        onDismiss?.();
                      }, 3000);
                    }
                  }}
                  setIsAbandonCartOpen={setIsAbandonCartOpen}
                />
              </div>
            ) : (
              <div className="flex-grow relative">
                <div className="relative z-10">
                  {carouselTestEnabled ? (
                    <CarouselV2
                      productType={product as ProductType}
                      onClose={handleDismiss}
                      openFeaturesModal={(productKey) =>
                        openFeaturesModal(productKey as ProductType)
                      }
                      showNoThanks={showNoThanks}
                    />
                  ) : (
                    <PremiumFeatures
                      openFeaturesModal={(productKey) =>
                        openFeaturesModal(productKey as ProductType)
                      }
                      productType={product as ProductType}
                      onClose={handleDismiss}
                      showNoThanks={showNoThanks}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!planType && !isSubscribed && (
        <>
          <div className={`${bgClass} flex justify-center`}>
            <div className="max-w-xl w-full overflow-hidden flex flex-col ">
              <PremiumProducts
                onMonthlyPress={onMonthlyPress}
                onAnnualPress={onAnnualPress}
              />
              {showNoThanks && (
                <h3
                  className={`text-center cursor-pointer text-lg ${
                    !payButtonVariant || payButtonVariant === 'control'
                      ? 'text-white hover:text-zinc-600'
                      : 'text-red-500 hover:text-red-700'
                  } hover:underline mt-2 mb-6`}
                  onClick={() => {
                    logEvent(
                      "no_thanks",
                      "profile_writer",
                      email,
                      {},
                      "payment"
                    );
                    window.scrollTo(0, 0);
                    
                    // Normal behavior for all flows (onboarding or not)
                    if (beforeYouGoModalTestEnabled && shouldShowBeforeYouGoModal(beforeYouGoModalTestEnabled)) {
                      setIsBeforeYouGoOpen(true);
                    } else {
                      setProfileWriterWizardComplete(true);
                    }
                  }}
                >
                  No, thanks
                </h3>
              )}
            </div>
          </div>
          <FamewallReviews
            containerStyle={showNoThanks ? "-px-2" : "px-2"}
            title="Testimonials"
          />
        </>
      )}
      <FeaturesModal
        initialKey={modalProduct}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        onClose={closeModal}
        handleGetStarted={() => {
          setIsModalOpen(false);
          setTimeout(() => {
            if (selectedPlan === "annual") {
              onAnnualPress();
            } else {
              onMonthlyPress();
            }
          }, 250);
        }}
      >
        <PremiumProductsCarousel
          initialKey={modalProduct}
          transparentBackground
        />
      </FeaturesModal>

      <BeforeYouGoModal
        open={isBeforeYouGoOpen}
        setOpen={setIsBeforeYouGoOpen}
        onClose={handleBeforeYouGoClose}
        handleGetStarted={() => {
          setIsBeforeYouGoOpen(false);
          if (selectedPlan === "annual") {
            onAnnualPress();
          } else {
            onMonthlyPress();
          }
        }}
      />

      <AbandonCartModal
        open={isAbandonCartOpen}
        setOpen={setIsAbandonCartOpen}
        onClose={() => setIsAbandonCartOpen(false)}
        handleGetStarted={() => {
          setIsAbandonCartOpen(false);
        }}
        onDismiss={onDismiss}
      />
    </>
  );
};
