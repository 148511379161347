import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import heartGraphic from "../../assets/images/misc/heart_graphic_paywall.webp";
import { useRef, useState, useEffect } from "react";
import { usePaymentStore } from "../../stores/payment";
import { PlanType } from "../../constants/payments";
import { setBeforeYouGoModalShown } from "../../stores/utils";
import { Dialog } from "@headlessui/react";
import { logEvent } from "../../analytics/analyticsUtils";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  children?: React.ReactNode;
  onClose: () => void;
  handleGetStarted: () => void;
  containerStyle?: string;
}

export const BeforeYouGoModal = ({
  open,
  setOpen,
  children,
  onClose,
  handleGetStarted: parentHandleGetStarted,
  containerStyle = "",
}: Props) => {
  const { setPlanType, setOpenPaymentModal } = usePaymentStore();
  const [dragStartY, setDragStartY] = useState<number | null>(null);
  const [currentDragY, setCurrentDragY] = useState<number>(0);
  const modalRef = useRef<HTMLDivElement>(null);

  // Set the timestamp when the modal is shown
  useEffect(() => {
    if (open) {
      setBeforeYouGoModalShown();
    }
  }, [open]);

  const handleGetStarted = () => {    
    // Set to annual plan by default
    setPlanType(PlanType.Yearly);
    // Close this modal
    setOpen(false);
    // Open payment modal
    setOpenPaymentModal(true);
    // Call parent handler if provided
    parentHandleGetStarted?.();
  };

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setDragStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (dragStartY === null) return;
    
    const currentY = e.touches[0].clientY;
    const deltaY = currentY - dragStartY;
    
    // Only allow dragging downwards
    if (deltaY > 0) {
      setCurrentDragY(deltaY);
    }
  };

  const handleTouchEnd = () => {
    // If dragged more than 150px down, close the modal
    if (currentDragY > 150) {
      handleClose();
    }
    
    // Reset drag state
    setDragStartY(null);
    setCurrentDragY(0);
  };

  return (
    <>
      <Transition
        show={open}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed inset-0 bg-black/60 backdrop-blur-sm z-[100] cursor-pointer"
        onClick={handleClose}
      />

      <Transition
        show={open}
        enter="transition ease-out duration-300"
        enterFrom="transform translate-y-full"
        enterTo="transform translate-y-0"
        leave="transition ease-in duration-200"
        leaveFrom="transform translate-y-0"
        leaveTo="transform translate-y-full"
        className={`fixed left-0 right-0 mx-auto bg-white rounded-t-[16px] p-6 md:p-8 lg:p-10 z-[101] shadow-xl bottom-0 w-full max-w-[450px] md:max-w-[650px] lg:max-w-[700px] max-h-[90vh] overflow-y-auto ${containerStyle}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-full mx-auto">
          {/* Grey bar indicator */}
          <div 
            className="flex justify-center -mt-2 mb-4 md:mb-6 cursor-grab active:cursor-grabbing sticky top-0 bg-white"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div className="w-16 h-1.5 rounded-full" style={{ backgroundColor: '#D1D1D6' }} />
          </div>

          <div className="flex flex-col items-center space-y-4 md:space-y-6 lg:space-y-8">
            <img 
              src={heartGraphic} 
              alt="Heart with envelopes"
              className="w-64 h-64 md:w-72 md:h-72 lg:w-80 lg:h-80 object-contain"
            />

            <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold text-[#410B13] leading-relaxed">
              Before You go ...
            </h2>

            <div className="text-center pb-4 md:pb-6">
              <p className="text-lg md:text-xl lg:text-2xl leading-relaxed max-w-2xl">
                Don't forget to subscribe and get more{' '}
                <span className="text-[#D05F6C] font-medium">matches and dates!</span>
              </p>
            </div>

            <div className="w-full space-y-6 md:space-y-8">
              <div className="max-w-sm md:max-w-md mx-auto w-full">
                <button
                  className="w-full py-4 md:py-5 bg-[#D93A3A] text-white text-base md:text-lg lg:text-xl font-semibold rounded-lg hover:bg-[#C33232] transition-colors"
                  onClick={handleGetStarted}
                >
                  Continue
                </button>
              </div>

              <div className="text-center space-y-3 md:space-y-4">
                <p className="text-sm md:text-base lg:text-lg text-gray-600 mb-2 flex items-center justify-center gap-2">
                  {/* <span className="line-through text-gray-500">$71.99</span> */}
                  <span>Only $1.50 per week, billed annually.</span>
                </p>
                <button
                  className="text-[#410B13] underline font-bold text-base md:text-lg hover:text-[#6B1D28] transition-colors py-2"
                  onClick={handleClose}
                >
                  I don't want it
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
}; 