import { Modal } from "./Modal";
import { PremiumV2 } from "../../pages/premium/PremiumV2";
import { ProductType } from "../../models/payment";
import { PlanType } from "../../constants/payments";
import { usePaymentStore } from "../../stores/payment";

type Props = {
  open: boolean
  product: ProductType
  planType?: PlanType
  onComplete?: VoidFunction
}

export const PremiumSubscriptionModal = ({ open, product, onComplete = undefined }: Props) => {
  const { setOpenPaymentModal, setPlanType } = usePaymentStore()

  const dismissModal = () => {
    setOpenPaymentModal(false)
    setTimeout(() => setPlanType(undefined), 500)
  }

  return (
    <Modal
      fullScreen
      open={open}
      setOpen={setOpenPaymentModal}
      backgroundColor="#f1f1f1"
    >
      <div className="flex flex-col h-full pt-12">
        <div className="overflow-y-auto h-full bg-white">
          <PremiumV2 product={product} onComplete={onComplete} onDismiss={dismissModal} />
        </div>
      </div>
    </Modal>
  );
};
