import React, { useEffect, useState } from 'react';
import { Onboarding } from '../../pages/Onboarding';
import { OnboardingFlow } from './OnboardingFlow';
import { useFeatureFlagVariantKey } from 'posthog-js/react';
import posthog from 'posthog-js';
import { logEvent } from '../../analytics/analyticsUtils';

// Import the images directly in the wrapper for preloading
import step1Image from '../../assets/images/onboarding/v3/step1.webp';
import step2Image from '../../assets/images/onboarding/v3/step2.webp';
import step3Image from '../../assets/images/onboarding/v3/step3.webp';
import step4Image from '../../assets/images/onboarding/v3/step4.webp';

interface OnboardingWrapperProps {
  defaultVariant?: string;
}

/**
 * Preloads an image by creating a new Image object and setting its src
 * @param src The image source URL
 */
const preloadImage = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve();
    img.onerror = (err) => reject(err);
    img.src = src;
  });
};

export const OnboardingWrapper: React.FC<OnboardingWrapperProps> = () => {
  console.log('[DEBUG] OnboardingWrapper rendering');
  
  // Get variant from PostHog feature flag
  const posthogVariant = useFeatureFlagVariantKey('extended-onboarding');
  console.log('[DEBUG] PostHog raw variant:', posthogVariant);
  
  const [isLoading, setIsLoading] = useState(true);
  const [finalVariant, setFinalVariant] = useState<string | null>(null);
  
  // Log current state for debugging
  console.log('[DEBUG] Current state:', { isLoading, finalVariant });
  
  // Handle variant resolution
  useEffect(() => {
    console.log('[DEBUG] useEffect for variant resolution running, posthogVariant:', posthogVariant);
    
    let timeoutId: NodeJS.Timeout;
    
    // Set up the timeout for fallback - increased to 1 second
    timeoutId = setTimeout(() => {
      console.log('[DEBUG] Timeout fired after 1000ms, current variant:', posthogVariant);
      
      if (isLoading) {
        const decidedVariant = typeof posthogVariant === 'string' ? posthogVariant : 'test';
        console.log('[DEBUG] Setting final variant after timeout to:', decidedVariant);
        
        // If we're still loading after timeout, use 'test' as fallback
        setFinalVariant(decidedVariant);
        setIsLoading(false);
      }
    }, 1000); // Increased timeout to 1 second
    
    // If variant is already available, use it
    if (posthogVariant !== undefined) {
      console.log('[DEBUG] Variant available immediately:', posthogVariant);
      clearTimeout(timeoutId);
      
      const decidedVariant = typeof posthogVariant === 'string' ? posthogVariant : 'test';
      console.log('[DEBUG] Setting final variant immediately to:', decidedVariant);
      
      setFinalVariant(decidedVariant);
      setIsLoading(false);
    }
    
    return () => {
      console.log('[DEBUG] Cleaning up timeout');
      clearTimeout(timeoutId);
    };
  }, [posthogVariant, isLoading]);
  
  // Check for re-renders
  useEffect(() => {
    console.log('[DEBUG] Loading state changed:', isLoading);
  }, [isLoading]);
  
  useEffect(() => {
    console.log('[DEBUG] Final variant changed:', finalVariant);
  }, [finalVariant]);
  
  // Preload images if needed
  useEffect(() => {
    if (finalVariant && finalVariant !== 'control') {
      console.log('[DEBUG] Preloading images for variant:', finalVariant);
      const imagesToPreload = [step1Image, step2Image, step3Image, step4Image];
      Promise.all(imagesToPreload.map(preloadImage)).catch(() => {});
    }
  }, [finalVariant]);
  
  // Track which variant is shown
  useEffect(() => {
    if (finalVariant) {
      console.log('[DEBUG] Tracking variant view:', finalVariant);
      logEvent('onboarding_variant_viewed', 'onboarding', undefined, { variant: finalVariant });
    }
  }, [finalVariant]);
  
  // Don't render anything until we have the final variant
  if (isLoading || !finalVariant) {
    console.log('[DEBUG] Rendering loading screen');
    return <div className="fixed inset-0 bg-white"></div>;
  }
  
  // Render the appropriate component
  console.log('[DEBUG] Rendering final component for variant:', finalVariant);
  return (
    <>
      {finalVariant === 'control' ? (
        <Onboarding />
      ) : (
        <>
          <div className="fixed inset-0 bg-white -z-10"></div>
          <OnboardingFlow />
        </>
      )}
    </>
  );
};