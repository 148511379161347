import { useEffect, useState, useRef } from 'react';
import { useProfileStore } from "../../stores/profile";
import { useWizardStore } from "../../stores/wizard";
import { logEvent } from "../../analytics";
import { ReviewedProfile } from "../../models/profile";
import { useUIStore } from "../../stores/ui";
import { SegmentedControlTabs } from "../../components/SegmentedControlTabs"
import { AIPhotoReview } from "../../pages/photo-review/AIPhotoReview";
import { ProfileReviewLoading } from "./ProfileReviewLoading"
import { ProfileReviewResult } from "./ProfileReviewResult"
import { YourMoveHeader } from '../YourMoveHeader';
import { ReviewType, WizardStepType } from '../../models/wizard';
import { FamewallReviews } from '../../pages/FamewallReviews';
import { useProfileReviewData } from '../../pages/profile-review/useProfileReviewData';
import { useAuthStore } from '../../stores/auth';
import { auth } from '../../firebase';
import { addToMailingList, checkUserSubscription } from '../../queries';
import { Loading } from '../Loading';
import { FaUndoAlt } from "react-icons/fa";

enum ReviewStep {
  Loading = "Loading",
  Review = "Review"
}

const segments = ['Profile', 'Photos'];

// Loading titles for the profile review process
const loadingTitles = [
  "Analyzing your profile's first impressions...",
  "Diving deep into your photos...",
  "Evaluating photo quality and lighting...",
  "Scouting for social proof in pictures...",
  "Rearranging your photo lineup for maximum impact...",
  "Reviewing your bio",
  "Generating custom style suggestions…",
  "Summarizing our findings…",
  "Crafting your profile upgrade plan…",
  "Preparing to unveil the potential of your profile…"
];

export const ProfileReview = () => {
  const {
    email,
    profileReviewerFiles,
    setProfileReviewerWizardComplete,
    reviewType,
    setReviewType,
  } = useWizardStore();
  const {
    setReviewedProfile,
    setHasPaidForProfileReview,
    reviewedProfile
  } = useProfileStore();
  const { isSubscribed, setIsSubscribed } = useAuthStore()
  const { setError, } = useUIStore()

  // Track the file IDs to detect changes
  const [processedFileIds, setProcessedFileIds] = useState<string[]>([]);
  const firstMount = useRef(true);

  // Set default review type to Profile when component initializes
  useEffect(() => {
    if (reviewType !== ReviewType.Profile) {
      setReviewType(ReviewType.Profile);
    }
  }, []);

  useEffect(() => {
    const userEmail = auth.currentUser?.email ?? email;
    const checkSubscription = async () => {
      if (userEmail) {
        const response = await checkUserSubscription(userEmail);
        addToMailingList(email, 'profile_review');
        setIsSubscribed(response.data.isSubscribed);
      }
    };
    checkSubscription();
  }, [])

  const getInitialStep = () => {
    if (reviewedProfile) return ReviewStep.Review
    return ReviewStep.Loading
  }

  const [currentStep, setCurrentStep] = useState<ReviewStep>(getInitialStep)
  const [loadingReview, setLoadingReview] = useState(true)
  const [initialLoad, setInitialLoad] = useState(true)

  const { fetchReview } = useProfileReviewData()

  // Generate file IDs from the current files to check for changes
  const getCurrentFileIds = () => {
    return profileReviewerFiles.map((file: string) => {
      // Since profileReviewerFiles is an array of strings (likely URLs or paths)
      // Create a unique ID from the file string itself
      return file;
    }).sort();
  };

  // Check if files have changed since the last review
  const haveFilesChanged = () => {
    if (profileReviewerFiles.length !== processedFileIds.length) return true;
    
    const currentIds = getCurrentFileIds();
    return currentIds.some((id, index) => id !== processedFileIds[index]);
  };

  useEffect(() => {
    // On first mount, or when files have changed and we have files to process
    const filesExist = profileReviewerFiles.length > 0;
    const needsReview = !reviewedProfile || haveFilesChanged();
    
    if (filesExist && needsReview) {
      // Reset the reviewed profile if files have changed
      if (!firstMount.current && haveFilesChanged() && reviewedProfile) {
        setReviewedProfile(null);
      }
      
      fetchProfileReview();
      // Update our record of processed files
      setProcessedFileIds(getCurrentFileIds());
    }
    
    // After first mount, track subsequent file changes
    if (firstMount.current) {
      firstMount.current = false;
    }
  }, [profileReviewerFiles, reviewedProfile]);

  const fetchProfileReview = async () => {
    try {
      logEvent('review_ai_start', 'profile_review')
      setLoadingReview(true)
      setCurrentStep(ReviewStep.Loading);

      const data = await fetchReview(email, profileReviewerFiles)
      setHasPaidForProfileReview(data.hasPaid || isSubscribed);
      setReviewedProfile(data as ReviewedProfile);
      setLoadingReview(false)
      setCurrentStep(ReviewStep.Review)
    }
    catch (error) {
      setError(
        "There was an error reviewing your profile, please try again later."
      );
      const params = (error as Error)?.message ? {
        reason: (error as Error)?.message
      } : undefined

      logEvent('review_ai_fail', 'profile_review', email, params, 'error')
      setLoadingReview(false)
    };
  }

  const handleSegmentChange = (index: number) => {
    const reviewType = index === 0 ? ReviewType.Profile : ReviewType.Photos;
    setReviewType(reviewType);
  };

  const onBack = () => {
    // Get the wizard store functions directly to ensure we're working with the latest state
    const wizardStore = useWizardStore.getState();
    
    // Clear the uploaded files
    wizardStore.setProfileReviewerFiles([]);
    
    // Clear the step result for the UPLOAD_PHOTO step to ensure UI is in sync
    wizardStore.setProfileReviewerStepResult(WizardStepType.UPLOAD_PHOTO, "[]");
    
    // Reset the review data
    setReviewedProfile(null);
    
    // Reset the processed file IDs to trigger a fresh review next time
    setProcessedFileIds([]);
    
    // Set the wizard step specifically to the upload photo step
    wizardStore.setProfileReviewerStep(WizardStepType.UPLOAD_PHOTO);
    
    // Mark the wizard as incomplete to return to the wizard flow
    wizardStore.setProfileReviewerWizardComplete(false);
    
    // Log the reset action
    logEvent('profile_review_reset', 'profile_review', email);
  }

  const onLoadingComplete = () => {
    if (!loadingReview) {
      setCurrentStep(ReviewStep.Review)
    }
  }

  // Show the full page loading spinner during the initial loading phase
  if (loadingReview && currentStep === ReviewStep.Loading) {
    return <Loading titles={loadingTitles} updateInterval={3000} />;
  }

  return (
    <>
      <div className="mx-auto max-w-xl flex flex-col h-full bg px-4">
        <div className="flex items-center py-4 py-2 mt-1">
          <div onClick={onBack} className="pr-4">
            <FaUndoAlt size={20} className="text-[#999999] hover:text-gray-600" />
          </div>
          
          <div className="flex-grow flex justify-center pl-2">
            <SegmentedControlTabs
              initialIndex={reviewType === ReviewType.Profile ? 0 : 1}
              segments={segments}
              onSegmentChange={handleSegmentChange}
            />
          </div>
        </div>
        
        <div className="">
          <p className="text-3xl font-semibold text-black mb-4 mt-4">
            {reviewType === ReviewType.Profile ? "Your Profile Analysis" : "Photo Analysis"}
          </p>
        </div>
        
        {reviewType === ReviewType.Profile ? (
          <div className="flex-grow">
            {currentStep === ReviewStep.Loading ? (
              <ProfileReviewLoading complete={!loadingReview} onLoadingComplete={onLoadingComplete} />
            ) : currentStep === ReviewStep.Review ? (
              <ProfileReviewResult />
            ) : null
            }
          </div>
        ) : (
          <AIPhotoReview />
        )}
      </div>
      {reviewType === ReviewType.Profile && currentStep === ReviewStep.Review && (
        <FamewallReviews
          containerStyle='-mx-4 px-4 mt-4'
          title='See what our users say'
        />
      )}
    </>
  );
};
