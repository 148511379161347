/**
 * Image Preloading Configuration
 * 
 * This file defines which images should be preloaded and when:
 * - GLOBAL_IMAGES: Key images that will be preloaded as soon as the app loads
 * - PAGE_SPECIFIC_IMAGES: Images that will be preloaded when specific pages are visited
 */

// Import images from assets (using static references to ensure proper bundling)
import reviewIllustration from '../assets/images/review-illustration.webp';
import reviewGraphicMid from '../assets/images/review-graphic-mid.webp';
import graphLikes from '../assets/images/graph_likes.webp';
import ai1 from '../assets/images/ai-1.png';
import ai2 from '../assets/images/ai-2.png';
import ai3 from '../assets/images/ai-3.png';
import ai4 from '../assets/images/ai-4.png';

// Onboarding images
import onboardPhotoImg from '../assets/images/onboarding/v2/onboard-photo.webp';
import onboardWriterImg from '../assets/images/onboarding/v2/onboard-writer.webp';
import onboardReviewImg from '../assets/images/onboarding/v2/onboard-review.webp';
import onboardTextingImg from '../assets/images/onboarding/v2/onboard-texting.webp';

// Premium images
import paywallGraphicNewDark from '../assets/images/premium/paywall_graphic_new_dark.webp';
import paywallGraphicNew from '../assets/images/premium/paywall_graphic_new.webp';

// AI Photos images
import good1 from '../assets/images/ai-photos/good-1.webp';
import good2 from '../assets/images/ai-photos/good-2.webp';
import good3 from '../assets/images/ai-photos/good-3.webp';
import bad1 from '../assets/images/ai-photos/bad-1.webp';
import bad2 from '../assets/images/ai-photos/bad-2.webp';
import bad3 from '../assets/images/ai-photos/bad-3.webp';

// Define page routes for better organization
export const ROUTES = {
  LANDING: '/',
  WELCOME: '/welcome',
  PREMIUM: '/premium',
  PROFILE_REVIEW: '/profile-review',
  AI_PHOTOS: '/ai-photo',
  AI_PHOTO_REVIEW: '/ai-photo-review',
  CHAT_ASSISTANT: '/chat-assistant',
  PROFILE_WRITER: '/profile-writer',
  ONBOARDING: '/start',
  ONBOARDING2: '/start2',
  AI_PHOTO_DEMO: '/ai-photo-demo',
  AI_PHOTO_DEMO_READY: '/ai-photo-demo-ready',
  NEXT_MOVE: '/next-move',
}

/**
 * Global images to preload immediately when the app loads
 * - Includes landing page graphics and paywall graphics
 */
export const GLOBAL_IMAGES = [
  // Landing page graphics
  reviewIllustration,
  reviewGraphicMid,
  graphLikes,
  
  // Paywall graphics
  ai1,
  ai2,
  ai3,
  ai4,
  paywallGraphicNew,
  paywallGraphicNewDark
];

/**
 * Page-specific images to preload when visiting certain pages
 * Map of route paths to arrays of image URLs
 */
export const PAGE_SPECIFIC_IMAGES: Record<string, string[]> = {
  '/profile-review': [
    // profileReviewImg,
    // reviewPremImg
  ],
  '/ai-photo': [
    // onboardPhotoImg,
    // aiPhotosPremImg,
    good1,
    good2,
    good3,
    bad1,
    bad2,
    bad3
  ],
  // '/ai-photo-review': [
  //   onboardPhotoImg,
  //   aiPhotosPremImg
  // ],
  // '/chat-assistant': [
  //   chatAssistantImg,
  //   chatPremImg
  // ],
  // '/profile-writer': [
  //   profileWriterImg,
  //   writerPremImg
  // ],
  '/start': [
    // Onboarding specific images
    // profileReviewImg,
    // profileWriterImg,
    // chatAssistantImg,
    onboardPhotoImg,
    onboardWriterImg,
    onboardReviewImg,
    onboardTextingImg
  ],
  '/next-move': [
    // Onboarding specific images
    // profileReviewImg,
    // profileWriterImg,
    // chatAssistantImg,
    onboardPhotoImg,
    onboardWriterImg,
    onboardReviewImg,
    onboardTextingImg
  ],
  // '/premium': [
  //   // Premium specific images
  //   writerPremImg,
  //   reviewPremImg,
  //   paywallGraphicNewDark,
  //   paywallGraphicNew,
  //   chatPremImg,
  //   aiPhotosPremImg
  // ],
  // Add other routes as needed
};

/**
 * Helper function to get images for a specific page
 * @param path Route path of the page
 * @returns Array of image URLs for the specified page
 */
export const getImagesForPage = (path: string): string[] => {
  // Find the most specific matching route
  const matchingRoute = Object.keys(PAGE_SPECIFIC_IMAGES).find(route => 
    path.startsWith(route)
  );
  
  return matchingRoute 
    ? PAGE_SPECIFIC_IMAGES[matchingRoute] 
    : [];
}; 