import { create } from "zustand";
import { ReviewType, WizardStepType } from "../models/wizard";
import { checkLocalStorageForInitialStep, checkLocalStorageForInitialStepResults, getBooleanFromLocalStorage, checkLocalStorageForFiles, checkLocalStorageForKey } from "./utils";

interface WizardStore {
  // For profile writer
  profileWriterStep: WizardStepType;
  setProfileWriterStep: (step: WizardStepType) => void;
  profileWriterWizardComplete: boolean;
  setProfileWriterWizardComplete: (wizardComplete: boolean) => void;
  profileWriterStepResults: Record<string, any>;
  setProfileWriterStepResult: (stepType: string, result: any) => void;

  // For profile reviewer
  profileReviewerStep: WizardStepType;
  setProfileReviewerStep: (step: WizardStepType) => void;
  profileReviewerWizardComplete: boolean;
  setProfileReviewerWizardComplete: (wizardComplete: boolean) => void;
  profileReviewerStepResults: Record<string, any>;
  setProfileReviewerStepResult: (stepType: string, result: any) => void;
  filesUploading: boolean;
  setFilesUploading: (filesUploading: boolean) => void;
  profileReviewerFiles: Array<string>;
  setProfileReviewerFiles: (files: Array<string>) => void;
  reviewType: ReviewType;
  setReviewType: (reviewType: ReviewType) => void;

  // Generic email field
  email: string;
  setEmail: (email: string) => void;

  // For ai photo review
  photoReviewStep: WizardStepType;
  setPhotoReviewStep: (step: WizardStepType) => void;
  photoReviewWizardComplete: boolean;
  setPhotoReviewWizardComplete: (wizardComplete: boolean) => void;
  photoReviewStepResults: Record<string, any>;
  setPhotoReviewStepResult: (stepType: string, result: any) => void;

  profileReviewStarted: boolean;
  setProfileReviewStarted: (profileReviewStarted: boolean) => void;

  welcomeStep: WizardStepType;
  setWelcomeStep: (welcomeStep: WizardStepType) => void;
  welcomeWizardComplete: boolean;
  setWelcomeWizardComplete: (welcomeWizardComplete: boolean) => void;
  welcomeStepResults: Record<string, any>;
  setWelcomeStepResult: (stepType: string, result: any) => void;
  welcomeReviewerFiles: Array<string>;
  setWelcomeReviewerFiles: (files: Array<string>) => void;
  welcomeFilesUploading: boolean;
  setWelcomeFilesUploading: (filesUploading: boolean) => void;
  welcomeReviewStarted: boolean;
  setWelcomeReviewStarted: (welcomeReviewStarted: boolean) => void;

  // For onboarding wizard (/start)
  onboardingStep: string;
  setOnboardingStep: (onboardingStep: string) => void;
  onboardingWizardComplete: boolean;
  setOnboardingWizardComplete: (onboardingWizardComplete: boolean) => void;
  onboardingStepResults: Record<string, any>;
  setOnboardingStepResult: (stepType: string, result: any) => void;

  demoStep: WizardStepType;
  setDemoStep: (demoStep: WizardStepType) => void;
  demoWizardComplete: boolean;
  setDemoWizardComplete: (demoWizardComplete: boolean) => void;
  demoStepResults: Record<string, any>;
  setDemoStepResult: (stepType: string, result: any) => void;
  demoReviewerFiles: Array<string>;
  setDemoReviewerFiles: (files: Array<string>) => void;
  demoFilesUploading: boolean;
  setDemoFilesUploading: (filesUploading: boolean) => void;
  demoReviewStarted: boolean;
  setDemoReviewStarted: (demoReviewStarted: boolean) => void;

  // Simplified shared answers
  sharedAnswers: Record<string, string>;
  setSharedAnswer: (key: string, value: string) => void;
  getSharedAnswer: (key: string) => string | undefined;
}

export const useWizardStore = create<WizardStore>((set, get) => ({
  // For profile writer
  profileWriterStep: checkLocalStorageForInitialStep(
    "profileWriter"
  ) as WizardStepType,
  setProfileWriterStep: (step: WizardStepType) =>
    set({ profileWriterStep: step }),
  profileWriterWizardComplete: false,
  setProfileWriterWizardComplete: (wizardComplete: boolean) =>
    set({ profileWriterWizardComplete: wizardComplete }),
  profileWriterStepResults: checkLocalStorageForInitialStepResults("profileWriter"),
  setProfileWriterStepResult: (stepType: string, result: any) => {
    set((state) => {
      const updatedStepResults = {
        ...state.profileWriterStepResults,
        [stepType]: result,
      };
      localStorage.setItem(
        "profileWriter:stepResults",
        JSON.stringify(updatedStepResults)
      );
      
      // Also update shared answers
      get().setSharedAnswer(stepType, result);
      
      return { profileWriterStepResults: updatedStepResults };
    });
  },

  // Profile Reviewer
  profileReviewerStep: checkLocalStorageForInitialStep(
    "profileReviewer"
  ) as WizardStepType,
  setProfileReviewerStep: (step: WizardStepType) =>
    set({ profileReviewerStep: step }),
  profileReviewerWizardComplete: getBooleanFromLocalStorage('profileReviewerWizardComplete'),
  setProfileReviewerWizardComplete: (wizardComplete: boolean) => {
    set({ profileReviewerWizardComplete: wizardComplete })
    localStorage.setItem('profileReviewerWizardComplete', wizardComplete.toString())
  },
  profileReviewerStepResults: checkLocalStorageForInitialStepResults("profileReviewer"),
  setProfileReviewerStepResult: (stepType: string, result: any) => {
    set((state) => {
      const updatedStepResults = {
        ...state.profileReviewerStepResults,
        [stepType]: result,
      };
      localStorage.setItem(
        "profileReviewer:stepResults",
        JSON.stringify(updatedStepResults)
      );
      get().setSharedAnswer(stepType, result);
      return { profileReviewerStepResults: updatedStepResults };
    });
  },
  profileReviewerFiles: checkLocalStorageForFiles("profileReviewer"),
  setProfileReviewerFiles: (files: Array<string>) => {
    set({ profileReviewerFiles: files })
    localStorage.setItem('profileReviewerFiles', JSON.stringify(files))
  },
  reviewType: ReviewType.Profile,
  setReviewType: (reviewType: ReviewType) =>
    set({ reviewType }),
  email: checkLocalStorageForKey("email"),
  setEmail: (email: string) => {
    set({ email });
    localStorage.setItem('email', email);
    
    // Track first product if it doesn't exist yet
    if (!localStorage.getItem('firstProduct')) {
        const path = window.location.pathname;
        localStorage.setItem('firstProduct', path);
        // console.log('First Product:', path);
    }
  },
  filesUploading: false,
  setFilesUploading: (filesUploading: boolean) =>
    set({ filesUploading: filesUploading }),

  // AI Photo review
  photoReviewStep: checkLocalStorageForInitialStep(
    "photoReview"
  ) as WizardStepType,
  setPhotoReviewStep: (step: WizardStepType) =>
    set({ photoReviewStep: step }),
  photoReviewWizardComplete: false,
  setPhotoReviewWizardComplete: (wizardComplete: boolean) =>
    set({ photoReviewWizardComplete: wizardComplete }),
  photoReviewStepResults: checkLocalStorageForInitialStepResults("photoReview"),
  setPhotoReviewStepResult: (stepType: string, result: any) => {
    set((state) => {
      const updatedStepResults = {
        ...state.photoReviewStepResults,
        [stepType]: result,
      };
      localStorage.setItem(
        "photoReview:stepResults",
        JSON.stringify(updatedStepResults)
      );
      
      // Also update shared answers
      get().setSharedAnswer(stepType, result);
      
      return { photoReviewStepResults: updatedStepResults };
    });
  },

  profileReviewStarted: false,
  setProfileReviewStarted: (profileReviewStarted: boolean) =>
    set({ profileReviewStarted: profileReviewStarted }),

  welcomeStep: checkLocalStorageForInitialStep(
    "welcome"
  ) as WizardStepType,
  setWelcomeStep: (step: WizardStepType) =>
    set({ welcomeStep: step }),
  welcomeStepResults: checkLocalStorageForInitialStepResults("welcome"),
  setWelcomeStepResult: (stepType: string, result: any) => {
    set((state) => {
      const updatedStepResults = {
        ...state.welcomeStepResults,
        [stepType]: result,
      };
      localStorage.setItem(
        "welcome:stepResults",
        JSON.stringify(updatedStepResults)
      );
      // Also update shared answers
      get().setSharedAnswer(stepType, result);
      return { welcomeStepResults: updatedStepResults };
    });
  },
  welcomeReviewerFiles: [],
  setWelcomeReviewerFiles: (files: Array<string>) =>
    set({ welcomeReviewerFiles: files }),
  welcomeFilesUploading: false,
  setWelcomeFilesUploading: (filesUploading: boolean) =>
    set({ welcomeFilesUploading: filesUploading }),
  welcomeWizardComplete: false,
  setWelcomeWizardComplete: (wizardComplete: boolean) =>
    set({ welcomeWizardComplete: wizardComplete }),
  welcomeReviewStarted: false,
  setWelcomeReviewStarted: (reviewStarted: boolean) =>
    set({ welcomeReviewStarted: reviewStarted }),

  // For onboarding wizard (/start)
  onboardingStep: checkLocalStorageForInitialStep(
    "onboarding"
  ) || "intro",
  setOnboardingStep: (step: string) => {
    set({ onboardingStep: step });
    localStorage.setItem('onboarding:step', step);
  },
  onboardingWizardComplete: getBooleanFromLocalStorage('onboardingWizardComplete'),
  setOnboardingWizardComplete: (wizardComplete: boolean) => {
    set({ onboardingWizardComplete: wizardComplete });
    localStorage.setItem('onboardingWizardComplete', wizardComplete.toString());
  },
  onboardingStepResults: checkLocalStorageForInitialStepResults("onboarding"),
  setOnboardingStepResult: (stepType: string, result: any) => {
    set((state) => {
      const updatedStepResults = {
        ...state.onboardingStepResults,
        [stepType]: result,
      };
      localStorage.setItem(
        "onboarding:stepResults",
        JSON.stringify(updatedStepResults)
      );
      
      // Also update shared answers
      get().setSharedAnswer(stepType, result);
      
      return { onboardingStepResults: updatedStepResults };
    });
  },

  demoStep: checkLocalStorageForInitialStep(
    "demo"
  ) as WizardStepType,
  setDemoStep: (step: WizardStepType) =>
    set({ demoStep: step }),
  demoStepResults: {},
  setDemoStepResult: (stepType: string, result: any) => {
    // Minimal implementation - just update shared answers
    get().setSharedAnswer(stepType, result);
  },
  demoReviewerFiles: checkLocalStorageForFiles("demoReviewer"),
  setDemoReviewerFiles: (files: Array<string>) => {
    localStorage.setItem('demoReviewerFiles', JSON.stringify(files))
    set({ demoReviewerFiles: files })
  },
  demoFilesUploading: false,
  setDemoFilesUploading: (filesUploading: boolean) =>
    set({ demoFilesUploading: filesUploading }),
  demoWizardComplete: false,
  setDemoWizardComplete: (wizardComplete: boolean) =>
    set({ demoWizardComplete: wizardComplete }),
  demoReviewStarted: false,
  setDemoReviewStarted: (reviewStarted: boolean) =>
    set({ demoReviewStarted: reviewStarted }),

  // Simplified shared answers
  sharedAnswers: checkLocalStorageForInitialStepResults(""),
  
  setSharedAnswer: (key, value) => {
    set((state) => {
      const updatedSharedAnswers = {
        ...state.sharedAnswers,
        [key]: value,
      };
      
      // Persist to localStorage
      localStorage.setItem('sharedAnswers', JSON.stringify(updatedSharedAnswers));
      
      // Special handling for email - match original pattern
      if (key === 'email') {
        set({ email: value });
        localStorage.setItem('email', value);
        
        // Track first product if it doesn't exist yet
        if (!localStorage.getItem('firstProduct')) {
          const path = window.location.pathname;
          localStorage.setItem('firstProduct', path);
          // console.log('First Product:', path);
        }
      }
      
      return { sharedAnswers: updatedSharedAnswers };
    });
  },
  
  getSharedAnswer: (key) => {
    return get().sharedAnswers[key];
  },
}));

