import { XMarkIcon } from "@heroicons/react/24/outline";
import { ProductType } from "../../../models/payment";
import { useEffect, useRef, useState } from "react";
import ChatIllustration from '../../../assets/images/premium/carouselv2/ChatIllustration.webp'
import ReviewIllustration from '../../../assets/images/premium/carouselv2/ReviewIllustration.webp'
import WriterIllustration from '../../../assets/images/premium/carouselv2/WriterIllustration.webp'
import AIPhotosIllustration from '../../../assets/images/premium/carouselv2/AIPhotosIllustration.webp'

interface CarouselDataItem {
  key: string;
  heading: string;
  image: string;
  title: string;
  description: string;
}

const carouselData: CarouselDataItem[] = [
  {
    key: "chat_assistant",
    heading: "Texting Wingman",
    title: "Put texting on cruise control",
    description: "Craft flirty replies or witty openers",
    image: ChatIllustration,
  },
  {
    key: "profile_review",
    heading: "Profile Review",
    title: "Enhance your profile",
    description: "Rate your profile & choose your best photos",
    image: ReviewIllustration,
  },
  {
    key: "profile_writer",
    heading: "Bio Writer",
    title: "Create the perfect bio",
    description: "Turn your best features into a profile",
    image: WriterIllustration,
  },
  {
    key: "photos",
    heading: "AI Photoshoot",
    title: "AI enhanced photos",
    description: "Double your swipes with a more charming you",
    image: AIPhotosIllustration,
  },
];

type CarouselCardProps = {
  title: string
  description: string
  illustration: string | undefined
  heading: string
}

const CarouselCard = ({ title, description, illustration, heading }: CarouselCardProps) => (
  <div className="w-full px-4 flex justify-center">
    <div className="w-full max-w-sm bg-[#FDF6F3] rounded-2xl px-2 py-6">
      <div className="text-center mb-4">
        <p className="text-[#952057] font-semibold uppercase tracking-wider text-sm mb-3">{heading}</p>
        <h3 className="text-[20px] font-bold text-[#2D1C26] mb-2 leading-tight">{title}</h3>
        <p className="text-[#2D1C26]/70 text-base leading-relaxed">{description}</p>
      </div>
      <div className="h-[200px] flex items-center justify-center">
        <img 
          src={illustration} 
          alt={heading}
          className="max-h-[200px] w-auto"
        />
      </div>
    </div>
  </div>
);

const reorderData = (
  data: CarouselDataItem[],
  firstKey: string | undefined = undefined
) => {
  if (!firstKey) return data;
  const key = firstKey === "photo_review" ? "profile_review" : firstKey;
  const index = data.findIndex((item) => item.key === key);
  if (index === -1) return data;
  return [data[index], ...data.slice(0, index), ...data.slice(index + 1)];
};

interface CarouselV2Props {
  productType: ProductType;
  onClose?: () => void;
  roundedTop?: boolean;
  onBack?: VoidFunction;
  openFeaturesModal: (productKey: string) => void;
  showNoThanks?: boolean;
}

export const CarouselV2: React.FC<CarouselV2Props> = ({
  productType,
  onClose,
  roundedTop,
  openFeaturesModal,
  showNoThanks = false,
}) => {
  const [step, setStep] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [dragDistance, setDragDistance] = useState(0);
  const [data, setData] = useState(() => reorderData(carouselData, productType));

  useEffect(() => {
    setData(reorderData(carouselData, productType));
  }, [productType]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isDragging) {
        setStep((prevStep) => (prevStep + 1) % carouselData.length);
      }
    }, 8000);

    return () => clearInterval(interval);
  }, [isDragging]);

  const handleDotClick = (index: number) => {
    setStep(index);
  };

  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
    setIsDragging(true);
    setStartX("touches" in e ? e.touches[0].pageX : e.pageX);
    setDragDistance(0);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    if (Math.abs(dragDistance) > 20) {
      if (dragDistance > 0 && step > 0) {
        setStep(step - 1);
      } else if (dragDistance < 0 && step < carouselData.length - 1) {
        setStep(step + 1);
      }
    }
    setDragDistance(0);
  };

  const handleDragMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging) return;
    const currentX = "touches" in e ? e.touches[0].pageX : e.pageX;
    const newDragDistance = currentX - startX;
    setDragDistance(newDragDistance);
  };

  return (
    <div className="flex flex-col h-full">
      {/* Hero Section */}
      <div className="flex flex-col bg-white pt-2 pb-4">
        {onClose && !showNoThanks && (
          <div className="mb-1">
            <button className="ml-4" onClick={onClose}>
              <XMarkIcon className="h-6 w-6 text-[#999999]" />
            </button>
          </div>
        )}
        <div className="text-center">
          <h2 className="text-2xl font-semibold leading-tight bg-gradient-to-r from-[#E85C65] to-[#952057] inline-block text-transparent bg-clip-text">
            10X your dating game
          </h2>
          <h3 className="text-2xl text-[#2D1C26] font-semibold mt-1">
            get more matches and dates
          </h3>
        </div>
      </div>

      {/* Carousel Section */}
      <div className="flex-grow bg-white">
        <div className="bg-transparent max-w-3xl mx-auto">
          <div
            className="flex flex-col flex-1 relative w-full overflow-hidden cursor-grab active:cursor-grabbing"
            ref={carouselRef}
            onMouseDown={handleDragStart}
            onMouseUp={handleDragEnd}
            onMouseLeave={handleDragEnd}
            onMouseMove={handleDragMove}
            onTouchStart={handleDragStart}
            onTouchEnd={handleDragEnd}
            onTouchMove={handleDragMove}
          >
            <div
              className="w-full inline-flex transition-transform duration-500 ease-in-out select-none"
              style={{
                transform: `translateX(calc(-${step * 100}% + ${
                  isDragging ? dragDistance : 0
                }px))`,
              }}
            >
              {data.map((item, index) => (
                <div
                  key={index}
                  className="w-full flex-shrink-0"
                  onDragStart={(e) => e.preventDefault()}
                >
                  <CarouselCard
                    illustration={item.image}
                    title={item.title}
                    description={item.description}
                    heading={item.heading}
                  />
                </div>
              ))}
            </div>
            
            {/* Dot Navigation */}
            <div className="flex justify-center gap-2 mt-2 mb-2">
              {data.map((_, index) => (
                <button
                  key={index}
                  className={`w-2 h-2 rounded-full transition-all duration-300 ${
                    index === step 
                      ? "bg-[#952057] w-4" 
                      : "bg-[#952057]/30"
                  }`}
                  onClick={() => handleDotClick(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}; 