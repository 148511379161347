import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GLOBAL_IMAGES, getImagesForPage } from '../constants/preloadImages';

// Track which images have already been preloaded to avoid duplicates
const preloadedImages = new Set<string>();

// Preloads a single image
const preloadImage = (src: string): Promise<void> => {
  if (preloadedImages.has(src)) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      preloadedImages.add(src);
      resolve();
    };
    img.onerror = () => {
      console.error(`Failed to preload image: ${src}`);
      reject(new Error(`Failed to preload image: ${src}`));
    };
    img.src = src;
  });
};

// Non-blocking image preloader that uses requestIdleCallback
const preloadImagesNonBlocking = (srcs: string[]): void => {
  // If browser supports requestIdleCallback, use it
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(() => {
      srcs.forEach(src => {
        preloadImage(src).catch(() => {
          // Error already logged in preloadImage function
          // This catch prevents unhandled promise rejections
        });
      });
    });
  } else {
    // For browsers without requestIdleCallback, use a zero-timeout 
    // to push to the end of the event queue without blocking
    setTimeout(() => {
      srcs.forEach(src => {
        preloadImage(src).catch(() => {
          // Error already logged in preloadImage function
        });
      });
    }, 0);
  }
};

export const GlobalPreloader: React.FC = () => {
  const location = useLocation();
  
  // Load global images on mount in a non-blocking way
  useEffect(() => {
    preloadImagesNonBlocking(GLOBAL_IMAGES);
  }, []);
  
  // Load page-specific images when route changes in a non-blocking way
  useEffect(() => {
    const pageImages = getImagesForPage(location.pathname);
    if (pageImages.length > 0) {
      preloadImagesNonBlocking(pageImages);
    }
  }, [location.pathname]);
  
  return null;
}; 