import { WizardStepType } from "../models/wizard";
import { usePaymentStore } from "./payment";
import { usePostHog } from 'posthog-js/react';

// Checks local storage for initial step based on wizard name
export const checkLocalStorageForInitialStep = (name: string) => {
  const step = localStorage.getItem(`${name}:step`);
  return step ? step : name === 'profileWriter' ? WizardStepType.WELCOME : name === 'photoReview' ? WizardStepType.EMAIL : WizardStepType.GENDER;
};

export const getBooleanFromLocalStorage = (key: string): boolean => {
  const value = localStorage.getItem(key);
  return value === 'true';
};

// Checks local storage for initial step results based on wizard name
export const checkLocalStorageForKey = (name: string) => {
  const result = localStorage.getItem(`${name}`);
  return result ?? "";
};

// Checks local storage for initial step results based on wizard name
// and merges with shared answers for smooth transition
export const checkLocalStorageForInitialStepResults = (name: string) => {
  // Get shared answers first
  const sharedAnswersStr = localStorage.getItem('sharedAnswers');
  const sharedAnswers = sharedAnswersStr ? JSON.parse(sharedAnswersStr) : {};
  
  // Then get wizard-specific answers if they exist
  const stepResults = localStorage.getItem(`${name}:stepResults`);
  
  // Create merged result with wizard-specific values taking precedence
  const result = { ...sharedAnswers };
  
  if (stepResults) {
    const stepResultsObject = JSON.parse(stepResults);
    // Merge, with wizard-specific values overriding shared values
    Object.assign(result, stepResultsObject);
    
    // Special handling for email (maintain original behavior)
    const email = localStorage.getItem(`email`);
    if (stepResultsObject?.email && !email) {
      localStorage.setItem(`email`, stepResultsObject.email);
    }
  }
  
  // Also check if we have email in shared answers but not in localStorage
  if (sharedAnswers.email) {
    const email = localStorage.getItem(`email`);
    if (!email) {
      localStorage.setItem(`email`, sharedAnswers.email);
    }
  }
  
  return result;
};

export const checkLocalStorageForFiles = (name: string) => {
  const files = localStorage.getItem(`${name}Files`);
  if (files) {
    return JSON.parse(files);
  }

  const profileReviewerResults = checkLocalStorageForInitialStepResults(name);
  if (profileReviewerResults && profileReviewerResults.uploadPhoto) {
    return JSON.parse(profileReviewerResults.uploadPhoto);
  }

  return [];
}

export const shouldShowBeforeYouGoModal = (beforeYouGoModalTestEnabled: boolean) => {
  const { beforeYouGoLastShownTime } = usePaymentStore.getState();
  
  // First check if the feature flag is enabled
  if (!beforeYouGoModalTestEnabled) return false;

  // Then check the timestamp
  if (!beforeYouGoLastShownTime) return true;

  const now = Date.now();
  const oneDay = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  
  return now - beforeYouGoLastShownTime >= oneDay;
};

export const shouldShowAbandonCartModal = (abandonCartTestEnabled: boolean) => {
  // First check if the feature flag is enabled
  if (!abandonCartTestEnabled) return false;

  const { abandonCartLastShownTime, beforeYouGoLastShownTime } = usePaymentStore.getState();
  
  // If we've shown abandon cart before, don't show it again
  if (abandonCartLastShownTime) return false;

  // If we've shown beforeYouGo in the last 12 hours, don't show abandon cart
  if (beforeYouGoLastShownTime) {
    const now = Date.now();
    const twelveHours = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
    if (now - beforeYouGoLastShownTime < twelveHours) return false;
  }

  return true;
};

export const setBeforeYouGoModalShown = () => {
  const { setBeforeYouGoLastShownTime } = usePaymentStore.getState();
  setBeforeYouGoLastShownTime(Date.now());
};

export const setAbandonCartModalShown = () => {
  const { setAbandonCartLastShownTime } = usePaymentStore.getState();
  setAbandonCartLastShownTime(Date.now());
};