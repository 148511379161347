import { useState } from "react"
import { SideNavListItem } from "../components/nav/SideNavListItem";
import { FeedbackModal } from "../components/modals/FeedbackModal";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal } from "../components/modals/Modal";
import { submitFeedback, unsubscribeDiscountOffer } from "../queries";
import toast from "react-hot-toast";
import { auth } from "../firebase";
import { useAuthStore } from "../stores/auth";
import { usePaymentStore } from "../stores/payment";

const STRIPE_UNSUBSCRIBE_LINK = "https://billing.stripe.com/p/login/9AQ14Sc4N0EX7E48ww"

export const UserSettings = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = auth.currentUser;
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [unsubscribeModalOpen, setUnsubscribeModalOpen] = useState(false);
  const { isSubscribed } = useAuthStore();
  const { setOpenPaymentModal, setProduct } = usePaymentStore();

  const handleDiscountAccept = async () => {
    if (user?.email) {
      try {
        // await submitFeedback({
        //   topic: "downgrade",
        //   email: user?.email || "unknown@user.com",
        //   details: "downgrade account payment to 50% off"
        // });

        await unsubscribeDiscountOffer(user.email);
        toast.success("Thanks for staying subscribed! Your payment has been updated.");
        setUnsubscribeModalOpen(false);
      } catch (error) {
        toast.error("Something went wrong. Please try again later.");
        console.error("Error submitting discount request:", error);
      }
    }
    else {
      toast.error("Something went wrong. Please try again later.");
      console.error("Could not get user email");
    }
  };

  const handleUnsubscribe = () => {
    window.open(STRIPE_UNSUBSCRIBE_LINK, '_blank');
    setUnsubscribeModalOpen(false);
  };

  const handleManageSubscription = () => {
    isSubscribed ? setUnsubscribeModalOpen(true) : setOpenPaymentModal(true)
  }

  return (
    <>
      <FeedbackModal open={feedbackModalOpen} setOpen={setFeedbackModalOpen} />
      <Modal
        open={unsubscribeModalOpen}
        setOpen={setUnsubscribeModalOpen}
      >
        <div className="p-4">
          <h2 className="text-xl font-semibold mb-6 -mt-6">Before You Go...</h2>
          <p className="mb-4 text-gray-700">
            We noticed you're thinking about canceling. How about staying with us for 50% off your current plan.
          </p>
          <p className="mb-4 text-gray-700">
            You'll keep access to everything, including:
          </p>
          <ul className="list-disc pl-6 mb-6 text-gray-700">
            <li>Profile reviews</li>
            <li>Photo feedback</li>
            <li>All the bios you've generated</li>
          </ul>
          <p className="mb-8 text-gray-700">
            This discount is only available now, and we'd love for you to stay!
          </p>
          <div className="flex flex-col gap-3">
            <button
              onClick={handleDiscountAccept}
              className="w-full bg-[#410B13] text-white px-4 py-2 rounded-lg hover:bg-opacity-90 transition-colors"
            >
              Yes, I'll stay for 50% off
            </button>
            <button
              onClick={handleUnsubscribe}
              className="w-full bg-gray-100 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors"
            >
              No, I'm okay losing access to my reviews and bios
            </button>
          </div>
        </div>
      </Modal>
      <div className="mx-auto max-w-xl pb-8">
        <div className="flex flex-col flex-1">
          <div className="w-full mt-8">
            <div className="flex items-center text-center justify-center mt-4 px-10 mb-10">
              <h1 className="text-2xl font-bold">
                User Settings
              </h1>
            </div>
            <ul className="mx-4">
              <SideNavListItem key="0" containerStyle="mt-auto mb-4" title="Contact Us" onNavItemClick={() => {
                setFeedbackModalOpen(true)
              }} />
              <SideNavListItem key="1" containerStyle="mt-auto mb-4" title="Request Account Deletion" target="_blank" onNavItemClick={() => {
                navigate("/request-delete")
              }} />
              <SideNavListItem
                key="2"
                containerStyle="mt-auto mb-4"
                title="Manage Subscription"
                onNavItemClick={() => {
                  if (isSubscribed) {
                    setUnsubscribeModalOpen(true);
                  } else {
                    setProduct('chat_assistant');
                    setOpenPaymentModal(true);
                  }
                }}
              />
              <SideNavListItem key="3" containerStyle="mt-auto mb-4" title="Refund Policy" href="/refund-policy" target="_blank" onNavItemClick={() => { }} />
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
