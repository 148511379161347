import { ProductType } from '../models/payment';
import { PlanType } from './../constants/payments';
import { create } from "zustand";

interface PaymentStore {
  openPaymentModal: boolean;
  setOpenPaymentModal: (openPaymentModal: boolean) => void;

  planType: PlanType | undefined;
  setPlanType: (planType: PlanType | undefined) => void;

  product: ProductType;
  setProduct: (product: ProductType) => void;
  
  selectedPlan: "annual" | "monthly";
  setSelectedPlan: (selectedProduct: "annual" | "monthly") => void;

  discountCode: string | undefined;
  setDiscountCode: (discountCode: string | undefined) => void;

  beforeYouGoLastShownTime: number | null;
  setBeforeYouGoLastShownTime: (timestamp: number | null) => void;

  abandonCartLastShownTime: number | null;
  setAbandonCartLastShownTime: (timestamp: number | null) => void;

  isAbandonCartFlow: boolean;
  setIsAbandonCartFlow: (isAbandonCartFlow: boolean) => void;
}

export const usePaymentStore = create<PaymentStore>((set) => ({
  openPaymentModal: false,
  setOpenPaymentModal: (openPaymentModal: boolean) => set({ openPaymentModal }),

  planType: undefined,
  setPlanType: (planType: PlanType | undefined) => set({ planType }),

  product: "chat_assistant",
  setProduct: (product: ProductType) => set({ product }),
  
  selectedPlan: "annual",
  setSelectedPlan: (selectedPlan: "annual" | "monthly") =>
    set({ selectedPlan }),

  discountCode: undefined,
  setDiscountCode: (discountCode: string | undefined) => set({ discountCode }),

  beforeYouGoLastShownTime: (() => {
    const stored = localStorage.getItem('beforeYouGo:lastShownTime');
    return stored ? parseInt(stored, 10) : null;
  })(),
  setBeforeYouGoLastShownTime: (timestamp: number | null) => {
    if (timestamp) {
      localStorage.setItem('beforeYouGo:lastShownTime', timestamp.toString());
    } else {
      localStorage.removeItem('beforeYouGo:lastShownTime');
    }
    set({ beforeYouGoLastShownTime: timestamp });
  },

  abandonCartLastShownTime: (() => {
    const stored = localStorage.getItem('abandonCart:lastShownTime');
    return stored ? parseInt(stored, 10) : null;
  })(),
  setAbandonCartLastShownTime: (timestamp: number | null) => {
    if (timestamp) {
      localStorage.setItem('abandonCart:lastShownTime', timestamp.toString());
    } else {
      localStorage.removeItem('abandonCart:lastShownTime');
    }
    set({ abandonCartLastShownTime: timestamp });
  },

  isAbandonCartFlow: false,
  setIsAbandonCartFlow: (isAbandonCartFlow: boolean) => set({ isAbandonCartFlow }),
}));
