type Props = {
  title: string
  description: string
  illustration: string | undefined
  heading?: string
}

export const PremiumProductStep = ({ title, description, illustration, heading }: Props) => (
  <div className="w-full px-4 flex justify-center">
    <div className="w-full max-w-sm rounded-2xl p-6">
      <div className="text-center">
        {heading && <p className="text-[#952057] font-semibold uppercase text-sm mb-4">{heading}</p>}
        <p className="text-[20px] font-bold text-black mb-2">{title}</p>
        <p className="text-black/70 mb-6 text-base leading-relaxed whitespace-normal">{description}</p>
      </div>
      <div className=" rounded-xl flex items-center justify-center overflow-hidden">
        {illustration ? (
          <img 
            src={illustration} 
            alt={title} 
            className="w-full h-auto rounded-xl"
          />
        ) : (
          <div className="text-center text-black/80 p-4">
            IMAGE PLACEHOLDER
          </div>
        )}
      </div>
    </div>
  </div>
);