import { useChatStore } from "../../stores/chat";
import { useAuthStore } from "../../stores/auth";
import { useUIStore } from "../../stores/ui";
import { usePaymentStore } from "../../stores/payment";
import posthog from 'posthog-js'; // Make sure to import PostHog
import { logEvent, logEventServerSide } from "../../analytics";
import { useWizardStore } from "../../stores/wizard";
import { useEffect } from "react";

export const PremiumUpsellPrompt = () => {
  const { chatResponse, setChatResponse } = useChatStore();
  const { isSubscribed } = useAuthStore();
  const { hideUpsell, currentProduct } = useUIStore();
  const { setOpenPaymentModal, setProduct } = usePaymentStore()
  const {email} = useWizardStore()
  
  // Store values in localStorage whenever chatResponse changes
  useEffect(() => {
    if (chatResponse?.queriesRemaining !== undefined) {
      localStorage.setItem('lastQueriesRemaining', chatResponse.queriesRemaining.toString());
    }
    if (chatResponse?.queriesAvailable !== undefined) {
      localStorage.setItem('lastQueriesAvailable', chatResponse.queriesAvailable.toString());
    }
  }, [chatResponse]);

  const openPaymentModal = () => {
    logEvent('purchase_unlock', currentProduct, email, {}, 'funnel')
    setProduct(currentProduct)
    setOpenPaymentModal(true)
  }

  if (!isSubscribed && !hideUpsell) {
    // Get values from chatResponse or localStorage
    const hasStoredValues = localStorage.getItem('lastQueriesRemaining') !== null;
    const queriesRemaining = chatResponse?.queriesRemaining ?? 
      (hasStoredValues ? parseInt(localStorage.getItem('lastQueriesRemaining') || '0') : undefined);
    const queriesAvailable = chatResponse?.queriesAvailable ?? 
      (hasStoredValues ? parseInt(localStorage.getItem('lastQueriesAvailable') || '10') : undefined);
    
    // Only show if we have the values and remaining is less than available
    if (queriesRemaining !== undefined && 
        queriesAvailable !== undefined && 
        queriesRemaining < queriesAvailable) {
      return (
        <div className="w-full mt-4">
          <div
            onClick={openPaymentModal}
            className="cursor-pointer bg-[#270006] text-white p-4 rounded-lg relative overflow-hidden"
            style={{ height: '72px' }}
          >
            {/* Lighter semicircle */}
            <div 
              className="absolute rounded-full bg-brand-primary"
              style={{
                width: '307px',
                height: '307px',
                left: '65.5px',
                top: '142.5px',
                transform: 'translate(-50%, -50%)',
                opacity: '.5'
              }}
            />
            
            <div className="relative z-10 flex flex-col h-full justify-center">
              <div className="text-xs sm:text-sm font-semibold mb-2">
                {queriesRemaining} / {queriesAvailable} free messages remaining today
              </div>
              <div className="text-xs sm:text-sm font-bold">
                Upgrade for unlimited messages, bios, and more
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return null;
};
