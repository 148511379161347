import { useEffect, useState } from 'react'
import { FamewallReviewsReview } from "./../FamewallReviews"
import ReviewIllustration from '../../assets/images/review-illustration.webp'
import { PrimaryButton } from '../../components/PrimaryButton';

const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

type Props = {
  onProfileReviewClick?: VoidFunction
}

export const ProfileReviewLanding = ({ onProfileReviewClick = undefined }: Props) => {

  const getInitialCount = () => {
    const savedCount = parseInt(localStorage.getItem("reviewsGenerated") ?? "0", 10);
    const savedTime = parseInt(localStorage.getItem("reviewsGeneratedTime") ?? "0", 10);
    const currentTime = Date.now();
    const oneHour = 60 * 60 * 1000; // One hour in milliseconds

    // If the saved time is missing or more than an hour old, reset the count
    const isTimeExpired = !savedTime || currentTime - savedTime >= oneHour;
    if (isTimeExpired) {
      const newCount = getRandomNumber(200, 300);
      localStorage.setItem("reviewsGenerated", newCount.toString());
      localStorage.setItem("reviewsGeneratedTime", currentTime.toString());
      return newCount;
    }

    // Use the saved count or fall back to a random number
    return savedCount > 0 ? savedCount : getRandomNumber(200, 300);
  };

  const [profileReviewsCount, setProfileReviewsCount] = useState(getInitialCount);

  useEffect(() => {
    if (profileReviewsCount) {
      localStorage.setItem("reviewsGenerated", profileReviewsCount.toString())
      localStorage.setItem("reviewsGeneratedTime", Date.now().toString())
    }
  }, [profileReviewsCount])

  useEffect(() => {
    const interval = setInterval(() => {
      setProfileReviewsCount((prevCount) => {
        const shouldStaySame = Math.random() < 0.3; // 30% chance to stay the same
        if (shouldStaySame) {
          return prevCount;
        }
        const newNumber = Math.min(prevCount + getRandomNumber(0, 5), 300);
        return newNumber;
      });
    }, 5000); // Update every second

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="mx-auto max-w-xl h-screen flex flex-col justify-center -pb-12 -mt-4 items-center" style={{ height: 'calc(100vh - 90px)' }} >
        <div className="flex flex-col justify-center items-center">
          <img src={ReviewIllustration} alt="profile" className="w-2/3 mx-auto -mt-12" />
          <h1 className="text-2xl font-bold mt-4 text-center px-6">Your profile is your dating resume</h1>
          <p className="text-base mt-4 text-center px-4">Optimize your profile and start getting 2x the matches overnight</p>
          <PrimaryButton
            containerClass="h-12 rounded-lg mt-6 mb-4 font-semibold min-w-80 hover:bg-brand-primary-dark p-6 text-base rounded-[32px] bg-gradient-to-b from-brand-primary to-[#FF8A65]"
            title="Review my profile"
            onClick={onProfileReviewClick}
          />
          <span className='self-center text-sm font-normal'>
            <span className='text-brand-primary text-base font-semibold'>
              {`${profileReviewsCount} profiles`}
            </span>
            {' '} reviewed in the last 24h
          </span>
        </div>
      </div>
      <div className="">
        <FamewallReviewsReview
          containerStyle='px-2'
          title='250,000+ happy customers'
          description='See what our customers say'
        />
      </div>
    </>
  )
}