import {
  WizardStepInputType,
  WizardStep as WizardStepModel,
  WizardStepType,
} from "../../models/wizard";
import { WizardProgress } from "./WizardProgress";
import { WizardStep, WizardStepRef } from "./WizardStep";
import { getStep, getStepIndex } from "../../utils";
import toast from "react-hot-toast";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAuthStore } from "../../stores/auth";
import { auth } from "../../firebase";
import { Loading } from "../Loading";
import { useWizardStore } from "../../stores/wizard";
import { AuthState } from "../../constants/auth";
import { Back } from "../Back";

/* Each Wizard has the following step:
1. Welcome
2. Wizard Steps
3. Paywall
4. Complete */

interface Props {
  // Take name for localStorage key
  name: string;
  steps: Array<WizardStepModel>;
  wizardComplete: boolean;
  setWizardComplete: (wizardComplete: boolean) => void;
  children: any;
  step: WizardStepType;
  setStep: (step: WizardStepType) => void;
  stepResults: Record<string, string>;
  setStepResult: (stepType: string, result: string) => void;
  storeStep?: boolean;
  onBackPress?: VoidFunction
  hasPaid?: boolean
  showProgress?: boolean
  autoSkipAnsweredSteps?: boolean
  stepsToAutoSkip?: WizardStepType[]
}

export const Wizard = ({
  name,
  steps,
  wizardComplete,
  setWizardComplete,
  children,
  step,
  setStep,
  stepResults,
  setStepResult,
  storeStep,
  hasPaid = false,
  onBackPress = undefined,
  showProgress = true,
  autoSkipAnsweredSteps = true,
  stepsToAutoSkip = [WizardStepType.GENDER, WizardStepType.GENDER_OTHER, WizardStepType.LOOKING_FOR]
}: Props) => {
  const { isSubscribed, authState } = useAuthStore();
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const { welcomeFilesUploading, email, setEmail, profileReviewerFiles } = useWizardStore();
  const [nextButtonTranslateY, setNextButtonTranslateY] = useState("32rem");
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const [profileGenerating, setProfileGenerating] = useState(false);
  const hasAutoSkippedRef = useRef(false);

  const wizardStepRef = useRef<WizardStepRef>(null);

  // Add validation for current step on component mount
  useEffect(() => {
    // Check if the current step exists in the steps array
    const stepExists = steps.some((wizardStep) => wizardStep.step === step);
    
    if (!stepExists && steps.length > 0) {
      console.warn(`Step ${step} not found in wizard steps. Redirecting to initial step.`);
      
      // Determine initial step based on wizard name
      let initialStep;
      
      if (steps[0]) {
        // Use the first step in the array as default
        initialStep = steps[0].step;
      } else {
        // Fallback to standard initial steps based on wizard type
        initialStep = name === 'profileWriter' 
          ? WizardStepType.WELCOME 
          : name === 'photoReview' 
            ? WizardStepType.EMAIL 
            : WizardStepType.GENDER;
      }
      
      // Update the step in state and localStorage
      setStep(initialStep);
      if (storeStep) {
        localStorage.setItem(`${name}:step`, initialStep);
      }
          }
  }, []); // Run only on component mount

  useEffect(() => {
    // Calculate translateY based on the viewport height
    // You might need to adjust the calculation to fit your design
    const viewportHeight = window.innerHeight;
    const dynamicTranslateY = `${viewportHeight * 0.63}px`; // for example, 80% of the viewport height

    setNextButtonTranslateY(dynamicTranslateY);
  }, []); // Empty dependency array ensures this runs on mount

  useEffect(() => {
    const viewport = window.visualViewport;
    if (viewport) {
      const handleResize = () => {
        if (viewport.height < window.innerHeight - 100) {
          setKeyboardOpen(true);
        } else {
          setKeyboardOpen(false);
        }
      };
      viewport.addEventListener('resize', handleResize);
      return () => viewport.removeEventListener('resize', handleResize);
    }
  }, []);

  // Checking if a payment has been made, if so we need to show some processing
  useEffect(() => {
    // We got a payment and a redirect
    if (window.location.search && step === WizardStepType.PAYWALL) {
      // Set that we are processing the payment
      setPaymentProcessing(true);

      // Get the search params
      const searchParams = new URLSearchParams(window.location.search);

      // Delete the params
      searchParams.delete("payment_intent");
      searchParams.delete("payment_intent_client_secret");
      searchParams.delete("redirect_status");

      // Create a new URL with the updated search parameters
      const newURL = `${window.location.pathname}?${searchParams.toString()}`;

      // Replace the current URL with the updated one
      window.history.replaceState({}, document.title, newURL);

      // Set a timeout (maybe a query to the API layer, to check if the payment is complete)
      setTimeout(() => {
        // setStep(ProfileStep.PROFILE);
        setWizardComplete(true);
        setPaymentProcessing(false);
      }, 3000);
    }
  }, [window.location]);

  useEffect(() => {
    if (storeStep) {
      localStorage.setItem(`${name}:step`, step);
      if (step === "email") {
        console.log("STORING EMAIL", email)
        // localStorage.setItem(`email`, email)
      } else {
        localStorage.setItem(
          `${name}:stepResults`,
          JSON.stringify(stepResults)
        );
      }
    }
  }, [step, stepResults, email])

  useLayoutEffect(() => {
    if (step === WizardStepType.EMAIL && authState === AuthState.Authenticated && auth.currentUser && auth.currentUser.email) {
      const nextStep = getStep(step, 1, steps);
      if (nextStep) setStep(nextStep.step)
      else setWizardComplete(true)
    }
  }, [authState])

  // Prefill values from shared answers
  useEffect(() => {
    if (autoSkipAnsweredSteps && !wizardComplete && stepsToAutoSkip.includes(step)) {
      const { sharedAnswers } = useWizardStore.getState();
      if (sharedAnswers[step]) {
        // Only update if the value is different to prevent infinite loops
        if (stepResults[step] !== sharedAnswers[step]) {
          // Just prefill the value
          setStepResult(step, sharedAnswers[step]);
        }
      }
    }
  }, [step, autoSkipAnsweredSteps, wizardComplete, stepsToAutoSkip, stepResults]);
  
  // Auto-skip once when the wizard is first loaded
  useEffect(() => {
    if (!hasAutoSkippedRef.current && autoSkipAnsweredSteps && !wizardComplete) {
      hasAutoSkippedRef.current = true;
      
      const { sharedAnswers } = useWizardStore.getState();
      
      // Check all steps that can be skipped
      let stepsToSkip: WizardStepType[] = [];
      let skipTargetIndex = -1;
      
      // First pass: identify all steps that should be skipped
      for (let i = 0; i < steps.length; i++) {
        const stepType = steps[i].step;
        
        if (stepsToAutoSkip.includes(stepType) && sharedAnswers[stepType]) {
          // Prefill the value for this step
          if (stepResults[stepType] !== sharedAnswers[stepType]) {
            setStepResult(stepType, sharedAnswers[stepType]);
          }
          stepsToSkip.push(stepType);
        }
      }
      
      // Second pass: find the first step that shouldn't be skipped after the current one
      if (stepsToSkip.length > 0) {
        const currentIndex = steps.findIndex(s => s.step === step);
        
        // If current step should be skipped, find the next non-skippable step
        if (stepsToSkip.includes(step)) {
          for (let i = currentIndex + 1; i < steps.length; i++) {
            if (!stepsToSkip.includes(steps[i].step)) {
              skipTargetIndex = i;
              break;
            }
          }
          
          // If we found a target to skip to, do it
          if (skipTargetIndex >= 0) {
            setStep(steps[skipTargetIndex].step);
          }
        }
      }
    }
  }, []);

  const goToNextStep = async () => {
    let nextStep = getStep(step, 1, steps);
    if (step === WizardStepType.WELCOME) {
      // Check if the next steps should be skipped due to shared answers
      if (autoSkipAnsweredSteps && nextStep) {
        const { sharedAnswers } = useWizardStore.getState();
        let targetStep = nextStep.step;
        let currentIndex = steps.findIndex(s => s.step === nextStep.step);
        let skipFound = false;
        
        // Skip forward until we find a step that shouldn't be skipped
        while (currentIndex < steps.length - 1) {
          // Check if the current step should be skipped
          if (stepsToAutoSkip.includes(targetStep) && sharedAnswers[targetStep]) {
            // Prefill the value for this step
            if (stepResults[targetStep] !== sharedAnswers[targetStep]) {
              setStepResult(targetStep, sharedAnswers[targetStep]);
            }
            skipFound = true;
            
            // Move to the next step
            currentIndex++;
            targetStep = steps[currentIndex].step;
          } else {
            // Found a step that shouldn't be skipped
            break;
          }
        }
        
        // If we found steps to skip, jump to the target
        if (skipFound) {
          setStep(targetStep);
          return; // Skip the normal setStep below
        }
      }
      
      // Normal welcome step handling if no skips
      setStep(nextStep.step);
    } else {
      let stepValue = stepResults[step];
      const currentStep = steps.find((wizardStep: WizardStepModel) => wizardStep.step === step);

      if (step === WizardStepType.EMAIL) {
        stepValue = wizardStepRef.current?.onNextPress() ?? ""
        console.log("WIZARD STEP VALUE FOR EMAIL", stepValue)
      }

      if (currentStep) {
        try {
          // If it has a validator
          if (currentStep.validator) {
            await currentStep.validator.validate(stepValue);
          }
          
          // Check if there are uploaded files for the UPLOAD_PHOTO step
          if (
            currentStep.step === WizardStepType.UPLOAD_PHOTO &&
            currentStep.type === WizardStepInputType.FILE
          ) {
            // Only check for the main profile review flow and ignore welcome/demo
            if (name === "profileReviewer") {
              if (!profileReviewerFiles || profileReviewerFiles.length === 0) {
                toast.error('Please upload at least one screenshot of your profile!')
                return
              }
              
              // If this is the last step of the profileReviewer wizard, show loading and complete wizard
              const isLastStep = !getStep(step, 1, steps);
              if (isLastStep) {
                setProfileGenerating(true);
                // Set wizard complete (this will render the ProfileReview component)
                setWizardComplete(true);
                return;
              }
            }
            // We're ignoring welcome and demo components entirely
          }
          
          // If the next step is "email" and the user is already authenticated
          if (nextStep && nextStep.step === WizardStepType.EMAIL && auth.currentUser && auth.currentUser.email) {
            // Get the step after the email step
            nextStep = getStep(nextStep.step, 1, steps);
          }
          
          // Check if the next steps should be skipped due to shared answers
          if (autoSkipAnsweredSteps && nextStep) {
            const { sharedAnswers } = useWizardStore.getState();
            let targetStep = nextStep.step;
            let currentIndex = steps.findIndex(s => s.step === nextStep.step);
            let skipFound = false;
            
            // Skip forward until we find a step that shouldn't be skipped
            while (currentIndex < steps.length - 1) {
              // Check if the current step should be skipped
              if (stepsToAutoSkip.includes(targetStep) && sharedAnswers[targetStep]) {
                // Prefill the value for this step
                if (stepResults[targetStep] !== sharedAnswers[targetStep]) {
                  setStepResult(targetStep, sharedAnswers[targetStep]);
                }
                skipFound = true;
                
                // Move to the next step
                currentIndex++;
                targetStep = steps[currentIndex].step;
              } else {
                // Found a step that shouldn't be skipped
                break;
              }
            }
            
            // If we found steps to skip, use the target as our next step
            if (skipFound) {
              nextStep = steps[currentIndex];
            }
          }
          
          if (nextStep) {
            setStep(nextStep.step);
          }
          // If there is no more next step, we are at the end
          if (!nextStep) {
            setWizardComplete(true);
          }
        } catch (error: any) {
          toast.error(error.message);
        }
      }
    }
  };

  // Check if it's Paywall and if it is, skip it if the user is subscribed
  useEffect(() => {
    if (auth.currentUser && isSubscribed && step == WizardStepType.PAYWALL) {
      goToNextStep();
    }
  }, [step]);

  const onBack = () => {
    const currentStep = getStepIndex(step, steps);

    if (currentStep === 0) {
      localStorage.removeItem(`${name}:step`);
      onBackPress?.();
      return;
    }

    // If this is the profile reviewer wizard, handle reset of uploaded files
    if (name === "profileReviewer") {
      // Get the wizard store functions directly to ensure we're working with the latest state
      const wizardStore = useWizardStore.getState();
      
      // Clear the uploaded files
      wizardStore.setProfileReviewerFiles([]);
      
      // Clear the step result for the UPLOAD_PHOTO step to ensure UI is in sync
      wizardStore.setProfileReviewerStepResult(WizardStepType.UPLOAD_PHOTO, "[]");
      
      // Log the reset action for debugging
      console.log("Reset profile reviewer files and step results");
    }

    let previousStep = getStep(step, -1, steps);

    // Check if the previous step is the email step and the user is already authenticated
    if (previousStep.step === "email" && auth.currentUser && auth.currentUser.email) {
      // Attempt to go back one more step if email is the previous step
      previousStep = getStep(previousStep.step, -1, steps);

      // If there's no previous step before email, handle it like going back from the 0th step
      if (!previousStep) {
        localStorage.removeItem(`${name}:step`);
        onBackPress?.();
        return;
      }
    }

    // Set the step to the previous step and store it in localStorage
    setStep(previousStep.step);
    localStorage.setItem(`${name}:step`, previousStep.step);
  }

  return (
    <div>
      {step !== WizardStepType.PAYWALL &&
        !paymentProcessing &&
        !wizardComplete &&
        !welcomeFilesUploading &&
        !profileGenerating && (
          <div
            //  Next button
            className="fixed right-6 z-10" style={{ bottom: keyboardOpen ? '12rem' : '7rem' }}
            onClick={() => goToNextStep()}
          >
            <div className="mt-auto bg-brand-primary w-12 h-12 flex items-center justify-center rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                className="w-8 h-8 stroke-white -mr-0.5 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          </div>
        )}
      {paymentProcessing ? (
        <Loading titles={["Generating profile..."]} />
      ) : profileGenerating ? (
        <Loading titles={[
          "Analyzing your profile's first impressions...",
          "Diving deep into your photos...",
          "Evaluating photo quality and lighting...",
          "Scouting for social proof in pictures...",
          "Rearranging your photo lineup for maximum impact...",
          "Reviewing your bio",
          "Generating custom style suggestions…",
          "Summarizing our findings…",
          "Crafting your profile upgrade plan…",
          "Preparing to unveil the potential of your profile…"
        ]} />
      ) : (
        <>
          {wizardComplete ? (
            <>{children}</>
          ) : (
            <>
              {step !== WizardStepType.PAYWALL ? (
                <div className="mx-auto w-full max-w-xl px-4">
                  <div className="mt-0">
                    {showProgress && <div className="mb-6">
                      <WizardProgress step={step} steps={steps} />
                    </div>}
                    {steps.map((wizardStep: WizardStepModel) => {
                      return (
                        <div key={wizardStep.step}>
                          {wizardStep.step === step ? (
                            <WizardStep
                              ref={wizardStepRef}
                              name={name}
                              key={wizardStep.label}
                              wizardStep={wizardStep}
                              goToNextStep={goToNextStep}
                              steps={steps}
                              step={step}
                              hasPaid={hasPaid}
                              email={email}
                              setEmail={!auth.currentUser?.email ? setEmail : undefined}
                              setStep={setStep}
                              stepResults={stepResults}
                              setStepResult={setStepResult}
                              onBackPress={onBackPress}
                            />
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div>
                  {/* <WizardProgress step={step} steps={steps} /> */}
                  <>
                    {/* <div className="-ml-2 mb-2">
                      <Back containerClass="w-6 h-10" onClick={onBack} />
                    </div> */}
                    {/* <div className="h-4 w-full"></div> */}
                    {
                      steps?.find((wizardStep: WizardStepModel) => {
                        return wizardStep.step === step;
                      })?.content
                    }
                  </>
                </div>
              )}
            </>
          )}
        </>
      )
      }
    </div >
  );
};
