import { MessageTypeSelector } from "../components/chat/selectors/MessageTypeSelector";
import { SettingsModal } from "../components/chat/modals/SettingsModal";
import { useChatStore } from "../stores/chat";
import { MessageInput, submitMessage } from "../components/chat/MessageInput";
import { Message as MessageComponent } from "../components/chat/Message";
import { PremiumUpsellPrompt } from "../components/chat/PremiumUpsellPrompt";
import { MessageAuthorType } from "../constants/chat";
import { Screenshot } from "../components/chat/Screenshot";
import { GeneratingRepliesLoader } from "../components/chat/GeneratingRepliesLoader";
import { useState, useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import { useLogEvent, logEvent } from '../analytics'
import { ChatOnboardingModal } from '../components/chat/onboarding/ChatOnboardingModal'
import { useWizardStore } from "../stores/wizard";
import { Message as MessageModel } from "../models/chat";
import { v4 as uuidv4 } from 'uuid';
import toast from "react-hot-toast";
import { useUserStore } from "../stores/user";
import { useUIStore } from "../stores/ui";
import { useAuthStore } from "../stores/auth";
import { usePaymentStore } from "../stores/payment";



export const ChatAssistant = () => {
  const {
    message,
    sendingMessage,
    screenshotUploading,
    chatResponse,
    setChatResponse,
    setScreenshotUploading,
    settingsModalOpen,
    setSettingsModalOpen,
    setChatRequestType,
    needReset,
  } = useChatStore();
  const { isCreator } = useUserStore()
  const { email } = useWizardStore()
  const { setError } = useUIStore();
  const { isSubscribed } = useAuthStore();
  const { setOpenPaymentModal, setProduct } = usePaymentStore();
  const { currentProduct } = useUIStore();

  const [responses, setResponses] = useState<MessageModel[]>([]);

  const [file, setFile] = useState<File | null>(null);
  const [showOnboarding, setShowOnboarding] = useState(false)

  // Start over, clear everything
  const startOver = () => {
    setFile(null);
    setChatResponse(null);
    setResponses([]);
    setScreenshotUploading(null);
    setChatRequestType(null);
  };

  // Check if the user has queries remaining and show payment modal if not
  const handleSubmitMessage = (message: string, file: File | null, email: string) => {
    // Only check remaining queries if user is not subscribed
    if (!isSubscribed) {
      // Get values from localStorage
      const hasStoredValues = localStorage.getItem('lastQueriesRemaining') !== null;
      const queriesRemaining = hasStoredValues ? 
        parseInt(localStorage.getItem('lastQueriesRemaining') || '0') : undefined;
      
      // If queriesRemaining is 0, show payment modal instead of submitting message
      if (queriesRemaining !== undefined && queriesRemaining === 0) {
        logEvent('purchase_unlock', currentProduct, email, {}, 'funnel');
        setProduct(currentProduct);
        setOpenPaymentModal(true);
        return; // Stop here, don't submit the message
      }
    }
    
    // Continue with normal submission if user has queries remaining or is subscribed
    submitMessage(message, file, email);
    onGetIdeasPress();
  };

  // Use the currentProduct from the UI store
  useLogEvent('start', currentProduct)

  // Apply a soft reset whenever a user who has a screenshot switches
  useEffect(() => {
    if (needReset) {
      startOver();
      useChatStore.getState().setNeedReset(false); // Reset the flag
    }
  }, [needReset]);

  useEffect(() => {
    const hasOnboardedChat = Boolean(parseInt(localStorage.getItem('hasOnboardedChat') ?? "0"))
    if (!hasOnboardedChat) {
      setShowOnboarding(true)
      localStorage.setItem("hasOnboardedChat", "1")
    }
  }, [])

  useEffect(() => {
    if (chatResponse) {
      // Assuming chatResponse.responses is an array of message content strings
      const newMessages = chatResponse.responses.map((content: string) => ({
        id: uuidv4(), // Generate a unique ID for each message
        content,
        author: MessageAuthorType.Generated,
        // Add other properties if necessary
      }));

      // Update the messages state with the new messages
      setResponses(newMessages);
    }
  }, [chatResponse])

  const handleSaveMessage = (messageId: string, newContent: string) => {
    setResponses((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === messageId ? { ...msg, content: newContent } : msg
      )
    );
    toast.success("Message saved");
  };

  const onGetIdeasPress = () => {
    logEvent('generate', currentProduct)
  }

  return (
    <div>
      <div className=" max-w-xl mx-auto px-4">
        <Helmet>
          <meta name="title" content="Texting Assistant" />
          <meta name="description" content="2x your matches and win the dating game with our AI dating assistant." />
        </Helmet>
        <div className="">
          <SettingsModal
            open={settingsModalOpen}
            setOpen={setSettingsModalOpen}
          />
          <MessageTypeSelector />
          <div className="mt-4 relative overflow-y-scroll">
            {chatResponse ? (
              <div className="h-full">
                {chatResponse.image || screenshotUploading ? (
                  <div className="mb-6 flex justify-start">
                    <div>
                      <Screenshot 
                        source={screenshotUploading || chatResponse.image} 
                        isLoading={false} 
                      />
                    </div>
                  </div>
                ) : (
                  <MessageComponent
                    message={{
                      content: message,
                      author: MessageAuthorType.User,
                    }}
                  />
                )}
                {sendingMessage ? (
                  <GeneratingRepliesLoader />
                ) : (
                  <>
                    {responses.map((response) => (
                      <div key={response.id}>
                        <MessageComponent
                          message={response}
                          isEditable={isCreator}
                          onSave={handleSaveMessage}
                        />
                      </div>
                    ))}
                    <div className="flex mt-4 font-semibold text-brand-primary cursor-pointer">
                      <div
                        className="mr-4"
                        onClick={() => {
                          // Use our new function to handle the remaining queries check
                          handleSubmitMessage(message, file, email);
                        }}
                      >
                        More Ideas
                      </div>
                      <div onClick={() => startOver()}>Start Over</div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <>
                {sendingMessage ? (
                  <>
                    {screenshotUploading ? (
                      <Screenshot
                        source={screenshotUploading}
                        isLoading={true}
                      />
                    ) : (
                      <MessageComponent
                        message={{
                          content: message,
                          author: MessageAuthorType.User,
                        }}
                      />
                    )}
                    <GeneratingRepliesLoader />
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            <MessageInput
              file={file}
              setFile={setFile}
              hideTextInput={!!chatResponse || sendingMessage}
              hideInputSettings={!sendingMessage}
              onGetIdeasPress={() => onGetIdeasPress()}
              onSubmitMessage={handleSubmitMessage}
            />
          </div>
          <PremiumUpsellPrompt />
        </div>
        <ChatOnboardingModal open={showOnboarding} setOpen={setShowOnboarding} />
      </div>
    </div>
  );
};
