import { useEffect, useRef, useState } from 'react';
import { UserInformationStep } from "./UserInformationStep";
import { AIPhotosWizardStep, getAIPhotosWizardStep, useAIPhotosStore } from "../../stores/aiphotos";
import { Back } from "../../components/Back";
import { UploadPhotosStep } from "./UploadPhotosStep";
import { PreviewPhotosStep } from "./PreviewPhotosStep";
import { getScrollableParent } from './utils';
import { AIPhotosPlan } from '../../constants/payments';
import { UserEmailStep } from './UserEmailStep';
import { Loading } from '../../components/Loading';
import StepsLoader from '../../components/StepsLoader';

// Add imports for wizard store and types
import { useWizardStore } from "../../stores/wizard";
import { WizardStepType } from "../../models/wizard";


type Props = {
  onBack: () => void;
  onWizardComplete: (selectedPlan: AIPhotosPlan) => void;
  experimentVariant: 'control' | 'treatment';
}

export const AIPhotosWizard = ({ onBack, onWizardComplete, experimentVariant }: Props) => {
  const { aiPhotosWizardStep, setAIPhotosWizardStep, setGender } = useAIPhotosStore();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const hasAutoSkippedRef = useRef(false);

  const WizardStep = getAIPhotosWizardStep(experimentVariant === 'treatment');

  const steps: string[] = [
    "Analyzing photo quality...",
    "Optimizing lighting and colors...",
    "Finding your best angles...",
    "Preparing professional enhancements...",
    "Getting everything ready..."
  ];

  // Add auto-skip effect to run once on component mount
  useEffect(() => {
    if (!hasAutoSkippedRef.current) {
      hasAutoSkippedRef.current = true;
      
      // Check for shared gender answer
      const { sharedAnswers } = useWizardStore.getState();
      const sharedGender = sharedAnswers[WizardStepType.GENDER];
      
      // If we have a shared gender answer and we're on the user information step
      if (sharedGender && aiPhotosWizardStep === WizardStep.USER_INFORMATION) {
        console.log('Auto-skipping gender step with shared answer:', sharedGender);
        
        // Set the gender value in AIPhotos store
        setGender(sharedGender);
        
        // Skip to the next step
        const nextStep = experimentVariant === 'treatment' 
          ? WizardStep.UPLOAD_IMAGES 
          : WizardStep.EMAIL_INPUT;
          
        setAIPhotosWizardStep(nextStep);
      }
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const scrollableParent = getScrollableParent(containerRef.current);
      if (scrollableParent) {
        const elementRect = containerRef.current.getBoundingClientRect();
        const parentRect = scrollableParent.getBoundingClientRect();
        const scrollTop = elementRect.top - parentRect.top + scrollableParent.scrollTop - 100; // Adjust 100 as needed

        scrollableParent.scrollTo({
          top: scrollTop,
          behavior: 'instant'
        });
      }
    }
  }, [aiPhotosWizardStep, isLoading]);

  const handleAIPhotosEmail = () => {
    handleContinue();
  }

  const handleContinue = async () => {
    const nextStep = aiPhotosWizardStep + 1;

    if (experimentVariant === 'treatment') {
      if (aiPhotosWizardStep === WizardStep.UPLOAD_IMAGES) {
        setIsLoading(true);
        await new Promise(resolve => setTimeout(resolve, 9000));
        setIsLoading(false);
      }
    } else {
      if (aiPhotosWizardStep === WizardStep.UPLOAD_IMAGES) {
        showLoading();
      }
    }
    
    setAIPhotosWizardStep(nextStep);
  }

  const showLoading = async () => {
    setIsLoading(true);
    await new Promise(resolve => setTimeout(resolve, 9000));
    setIsLoading(false);
  }

  const handleWizardComplete = (selectedPlan: AIPhotosPlan) => {
    onWizardComplete(selectedPlan);
  };

  const handleBack = () => {
    if (aiPhotosWizardStep === 1) {
      onBack();
    } else {
      const prevStep = aiPhotosWizardStep - 1;
      setAIPhotosWizardStep(prevStep);
    }
  };

  const renderStep = () => {
    if (isLoading) {
      // return <Loading titles={['Validating photos...']} />;
      return <StepsLoader steps={steps} title='Processing your photos...' />;
    }

    if (experimentVariant === 'treatment') {
      switch (aiPhotosWizardStep) {
        case WizardStep.USER_INFORMATION:
          return <UserInformationStep onContinuePress={handleContinue} isTreatment={true} />;
        case WizardStep.UPLOAD_IMAGES:
          return <UploadPhotosStep onContinue={handleContinue} />;
        case WizardStep.EMAIL_INPUT:
          return <UserEmailStep onContinuePress={handleAIPhotosEmail} />;
        default:
          return <PreviewPhotosStep onContinue={handleWizardComplete} />;
      }
    } else {
      switch (aiPhotosWizardStep) {
        case WizardStep.USER_INFORMATION:
          return <UserInformationStep onContinuePress={handleContinue} isTreatment={false} />;
        case WizardStep.EMAIL_INPUT:
          return <UserEmailStep onContinuePress={handleAIPhotosEmail} />;
        case WizardStep.UPLOAD_IMAGES:
          return <UploadPhotosStep onContinue={handleContinue} />;
        default:
          return <PreviewPhotosStep onContinue={handleWizardComplete} />;
      }
    }
  };

  return (
    <div ref={containerRef} className="max-w-lg mx-auto h-screen">
      <div className="mt-1 px-4 md:px-0 mb-6 h-full">
        <div className="flex items-center mb-4">
          {/* <Back containerClass="w-6 h-10" color="stroke-[#999999]" onClick={handleBack} />
          <span className="ml-auto text-sm text-gray-500">
            Step {aiPhotosWizardStep}/{Object.keys(WizardStep).length / 2}
          </span> */}
          {!isLoading &&
            <>  
              <Back containerClass="w-6 h-10" color="stroke-[#999999]" onClick={handleBack} />
              <span className="ml-auto text-sm text-gray-500">
                Step {aiPhotosWizardStep}/{Object.keys(WizardStep).length / 2}
              </span>
            </>
          }
        </div>
        {renderStep()}
      </div>
    </div>
  );
};