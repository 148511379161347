import { Dropdown } from "./Dropdown";
import { ETHNICITY_OPTIONS, AGE_OPTIONS, EYE_COLOR_OPTIONS } from './options'
import { useAIPhotosStore } from "../../stores/aiphotos";

type Props = {
  onContinuePress: () => void
  isTreatment: boolean
}

export const UserInformationStep = ({ onContinuePress, isTreatment }: Props) => {
  const { gender, ethnicity, age, eyeColor, setGender, setEthnicity, setAge, setEyeColor } = useAIPhotosStore()

  // Calculate if the form is complete based on treatment/control
  const isFormComplete = isTreatment 
    ? gender !== '' // Only check gender for treatment
    : gender !== '' && ethnicity !== '' && age !== '' && eyeColor !== '' // Check all fields for control

  return (
    <div className="flex flex-col h-full max-w-md mx-auto">
      <div className="flex-grow overflow-y-auto">
        <h1 className="text-2xl font-bold mb-6">
          {isTreatment ? "You are a ..." : "Let's start with some basics"}
        </h1>

        <div className="space-y-4 mb-8">
          <div>
            {!isTreatment && <h4 className="font-medium text-sm mb-2">Gender</h4>}
            <div className="flex flex-wrap gap-4 justify-start">
              <button
                className={`px-4 py-2 rounded-full border bg-white ${gender === "Man"
                  ? "border-[#BA1F33] text-[#BA1F33]"
                  : "border-gray-300 text-gray-700"
                  }`}
                onClick={() => setGender("Man")}
              >
                Man
              </button>
              <button
                className={`px-4 py-2 rounded-full border bg-white ${gender === "Woman"
                  ? "border-[#BA1F33] text-[#BA1F33]"
                  : "border-gray-300 text-gray-700"
                  }`}
                onClick={() => setGender("Woman")}
              >
                Woman
              </button>
              <button
                className={`px-4 py-2 rounded-full border bg-white ${gender === "Other"
                  ? "border-[#BA1F33] text-[#BA1F33]"
                  : "border-gray-300 text-gray-700"
                  }`}
                onClick={() => setGender("Other")}
              >
                Other
              </button>
            </div>
          </div>

          {!isTreatment && (
            <>
              <div>
                <h4 className="font-medium text-sm mb-2">Ethnicity</h4>
                <Dropdown
                  options={ETHNICITY_OPTIONS}
                  value={ethnicity.charAt(0).toUpperCase() + ethnicity.slice(1)}
                  onChange={(value) => setEthnicity(value.toLowerCase())}
                  placeholder="Choose"
                />
              </div>

              <div>
                <h4 className="font-medium text-sm mb-2">Age</h4>
                <Dropdown
                  options={AGE_OPTIONS}
                  value={age.charAt(0).toUpperCase() + age.slice(1)}
                  onChange={(value) => setAge(value.toLowerCase())}
                  placeholder="Choose"
                />
              </div>

              <div>
                <h4 className="font-medium text-sm mb-2">Eye Color</h4>
                <Dropdown
                  options={EYE_COLOR_OPTIONS}
                  value={eyeColor.charAt(0).toUpperCase() + eyeColor.slice(1)}
                  onChange={(value) => setEyeColor(value.toLowerCase())}
                  placeholder="Choose"
                />
              </div>
            </>
          )}
        </div>
        <button
          onClick={onContinuePress}
          disabled={!isFormComplete}
          className={`w-full py-3 rounded-md font-semibold ${
            isFormComplete
              ? "bg-brand-primary text-white"
              : "bg-gray-300 text-gray-500 cursor-not-allowed"
          }`}
        >
          Continue
        </button>
      </div>
    </div>
  )
}