import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PremiumProductsCarousel } from "../../components/payment/paywalls/PremiumProductsCarousel";
import { ProductType } from "../../models/payment";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  children?: React.ReactNode;
  initialKey?: ProductType;
  onClose: () => void;
  handleGetStarted: () => void;
  containerStyle?: string;
}

export const FeaturesModal = ({
  open,
  setOpen,
  children,
  initialKey,
  onClose,
  handleGetStarted,
  containerStyle,
}: Props) => {
  return (
    <>
      <Transition
        show={open}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed inset-0 bg-black/80 backdrop-blur-sm z-40"
      />

      <Transition
        show={open}
        enter="transition ease-out duration-300"
        enterFrom="transform translate-y-full"
        enterTo="transform translate-y-0"
        leave="transition ease-in duration-200"
        leaveFrom="transform translate-y-0"
        leaveTo="transform translate-y-full"
        className={`fixed left-0 right-0 max-w-xl mx-auto bg-white rounded-t-lg p-4 z-50 shadow-xl bottom-0 ${containerStyle}`}
      >
        <div className="lg:max-w-2xl w-full mx-auto">
          <div className="flex justify-center items-center mb-4">
            <div className="w-1/3" />
            <div className="w-1/3 flex justify-center">
              <h4 className="font-bold text-xl">Features</h4>
            </div>
            <div className="flex items-end justify-end flex-1">
              <XMarkIcon
                className="w-8 h-8 text-gray-500 cursor-pointer"
                onClick={() => setOpen(false)}
              />
            </div>
          </div>

          {children || (
            <PremiumProductsCarousel
              initialKey={initialKey}
              transparentBackground
            />
          )}

          <button
            className="w-full py-3 bg-[#410B13] text-white rounded-lg shadow-lg mt-4 mb-4 font-medium text-base"
            onClick={handleGetStarted}
          >
            Get Started
          </button>
        </div>
      </Transition>
    </>
  );
};